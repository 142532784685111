const BLK2GO = {
  headerItems: [
    "overview",
    "technology",
    "use cases",
    "specifications",
    "request a demo",
  ],
  landing: {
    productName: "BLK2GO",
    videoLink: "//player.vimeo.com/video/498345012?background=1",
  },
  advertise: {
    title: "The power of reality capture technology in the palm of your hand.",
    videoLink: "https://www.youtube.com/embed/Eg7KvPUYwdY",
    paragraph:
      "The BLK2GO from Leica Geosystems is the fastest and easiest way to scan any large space or structure. While you simply walk through your surroundings, the BLK2GO collects millions of data points, creating a digital twin of your environment for creating accurate floorplans, BIM models, and 3D visualizations. The BLK2GO makes reality capture and the power of point clouds accessible to everyone. ",
  },
  sketchfab: {
    gradientColor: "#161616",
    modelLink:
      "https://sketchfab.com/models/4deafe0c4e2d410c862de86f8197e7c4/embed?camera=0&preload=1&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_watermark_link=0&ui_watermark=0&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&ui_color=db0d0d&amp;scrollwheel=0",
  },
  videoSections: [
    {
      id: "PRESENTING",
      title: "POWERFUL AND COMPACT",
      video: "https://www.youtube.com/embed/JmjszipCyAE",
      paragraph:
        "The BLK2GO boasts a suite of industry-leading, innovative features that make it the most impressive imaging scanner on the market. With the touch of a button, gather millions of measurements in minutes, with incredible accuracy and speed. With no need for a tripod or specialized training, laser scanning is no longer only the realm of professional surveyors.",
    },
    {
      id: "USING",
      title: "BEAUTIFUL SIMPLICITY",
      video: "https://www.youtube.com/embed/JmjszipCyAE",
      paragraph:
        "With it’s simple-one button operation, anyone can use the BLK2GO straight out of the box. Connect to your mobile device in seconds, and start gathering an amazing amount of data as you walk through your site. In only minutes, you’ll have a usable set of millions of data points, enabling you to supercharge your workflow and create amazing deliverables for your clients.",
    },
    {
      id: "DEMONSTRATING",
      title: "SUPERCHARGED PERFORMANCE",
      video: "https://www.youtube.com/embed/JmjszipCyAE",
      paragraph:
        "Indoors or out, the BLK2GO is your go-to tool for gathering millions of measurements quickly and easily. After wirelessly connecting the BLK2GO to your mobile device, you can track your data capture live in one hand while you walk through your space. Importing your data into the software of your choice is simple, efficient, and fast with wireless or wired data transfer options.",
    },
    {
      id: "UNBOXING",
      title: "A COMPLETE SOLUTION FOR YOUR MEASUREMENT NEEDS",
      video: "https://www.youtube.com/embed/JmjszipCyAE",
      paragraph:
        "When you purchase a BLK2GO, you’re immediately equipped to gather millions of measurements faster than ever before. With everything you need to get started packed right into the box, you can get your team working with this revolutionary technology in just minutes. ",
    },
  ],
  features: [
    {
      title: "TECHNOLOGY",
      topVideo: true,
      // https://player.vimeo.com/video/502327728?background=1 blob:https://player.vimeo.com/7418b99c-bf51-4d31-9461-ff3b85746462
      mediaLink: "https://player.vimeo.com/video/502327728?background=1",
      sections: [
        {
          title: "Sensor Fusion",
          image:
            "https://shop.leica-geosystems.com/sites/default/files/styles/gallery_large/public/2020-12/CES2021-Gianni-BLK2GO.png",
          paragraph:
            "The BLK2GO uses real-time LiDAR scanning and SLAM tracking for mobile mapping of 3D spaces.",
        },
        {
          title: "3D Point Clouds",
          image:
            "https://shop.leica-geosystems.com/sites/default/files/styles/gallery_large/public/2021-02/Full_DMS_School_Combo_Camera3_2ndFloor.png",
          paragraph:
            "The BLK2GO’s enclosed dual-axis LiDAR sensor captures data in three dimensions as you walk through a space.",
        },
        {
          title: "Point & Shoot Pictures",
          image:
            "https://shop.leica-geosystems.com/sites/default/files/styles/gallery_large/public/2021-01/point_shoot.jpg",
          paragraph:
            "Take high-resolution images while scanning with the BLK2GO Point & Shoot camera. These images are automatically geotagged in the point cloud at the exact position where they were taken.",
        },
        {
          title: "IMU",
          image:
            "https://shop.leica-geosystems.com/sites/default/files/2021-01/BLK2GO_11121-with-lightband_0.jpg",
          paragraph:
            "While you walk, the IMU (Inertial Measurement Unit) senses your movement to calculate the BLK2GO’s change of position in 3D space.",
        },
      ],
    },
    {
      title: "USE CASES",
      topVideo: true,
      mediaLink: "https://player.vimeo.com/video/502327728?background=1",
      sections: [
        {
          title: "Architecture",
          image:
            "https://shop.leica-geosystems.com/sites/default/files/styles/blog_post_featured_image_xl/public/2021-02/glasshouse2.PNG",
          paragraph:
            "Architects and other AEC professionals use the BLK2GO to document as-built conditions for the restoration and renovation of existing spaces. Capture massive buildings with speed, accuracy, and efficiency to create the foundation for BIM designs and CAD drawings.",
        },
        {
          title: "Facility Management",
          image:
            "https://shop.leica-geosystems.com/sites/default/files/styles/blog_post_featured_image_xl/public/2021-01/BLK2GO_walking.jpg",
          paragraph:
            "Facility managers can use BLK2GO scans to make accurate and informed decisions about their properties. Detailed floorplans allow facility managers to understand and improve the layout of specific facilities and to better understand the precise location of assets in their key directories.",
        },

        {
          title: "Space Planning",
          image:
            "https://shop.leica-geosystems.com/sites/default/files/styles/blog_post_featured_image_xl/public/2020-12/BLK2GO_9944-lightband.jpg",
          paragraph:
            "The BLK2GO can be used to map large and complex buildings like hospitals, offices, and college campuses with unprecedented speed. The accurate floorplans that can be built from BLK2GO data streamline the retrofit process.",
        },
        {
          title: "Real Estate",
          image:
            "https://shop.leica-geosystems.com/sites/default/files/styles/blog_post_featured_image_xl/public/2021-02/WurthHaus_0.PNG",
          paragraph:
            "In real estate, every square meter counts. Accurate floorplans generated with BLK2GO ensure that owners get what their property is worth, and buyers know exactly what they are paying for.",
        },
        {
          title: "Engineering",
          image:
            "https://shop.leica-geosystems.com/sites/default/files/styles/blog_post_featured_image_xl/public/2019-09/register_blk2go_data.png",
          paragraph:
            "Engineers bring their problem-solving skills to a project. When designing with specific goals in mind, engineers require the right tools; with the BLK2GO, they can scan spaces and digitally analyze, design, and build the best solutions for the project at hand.",
        },
      ],
    },
  ],
  statistics: {
    specsTitle: "Specifications",
    specsVideo:
      "https://player.vimeo.com/video/500181508?background=1&autoplay=1",
    smallTitle: "Get the specs.",
    statsImage:
      "https://shop.leica-geosystems.com/sites/default/files/inline-images/BLK2GO-measurement-drawing_v2_0.png",
    statsItems: [
      {
        section: "DESIGN & PHYSICAL",
        stats: [
          {
            above: "HOUSING",
            under: "Black anodized alumininium",
          },
          {
            above: "DIMENSIONS",
            under: "WIDTH: 80mm, HEIGHT: 279mm, ",
          },
          {
            above: "WEIGHT",
            under: "650g (775g including battery)",
          },
          {
            above: "MOUNTING",
            under: "",
          },
        ],
      },
      {
        section: "OPERATION",
        stats: [
          {
            above: "ON SCANNER",
            under: "One-button operation",
          },
          {
            above: "CONTROL",
            under:
              "BLK2GO Live app for iPhone (iOS 12.1 or later) including: live 2D and 3D visualization while scanning, device status and data management",
          },
          {
            above: "WIRELESS",
            under: "app conection",
          },
          {
            above: "DATA STORAGE",
            under:
              "24 hours of scanning (compressed data) / 6 hours (uncompressed data)",
          },
        ],
      },
      {
        section: "POWER",
        stats: [
          {
            above: "INTERNAL",
            under: "",
          },
          {
            above: "EXTERNAL",
            under: "",
          },
        ],
      },
      {
        section: "SCANNING",
        stats: [
          {
            above: "DISTANCE MEASUREMENT",
            under: "",
          },
          {
            above: "LASER CLASS",
            under: "1 (in accordance with IEC 60825-1)",
          },
          {
            above: "FIELD OF VIEW",
            under: "360° (HORIZONTAL), 270° (VERTICAL)",
          },
          {
            above: "RANGE",
            under: "0.5m (MIN), 25m (MAX)",
          },
          {
            above: "SPEED",
            under: "",
          },
          {
            above: "RESOLUTION",
            under: "",
          },
        ],
      },
      {
        section: "IMAGING",
        stats: [
          {
            above: "CAMERA",
            under: "12 Mpixel, 90° x 120°, rolling shutter",
          },
          {
            above: "SPEED",
            under: "",
          },

          {
            above: "HDR",
            under: "",
          },
          {
            above: "THERMAL",
            under: "",
          },
        ],
      },
      {
        section: "ENVIRONMENTAL",
        stats: [
          {
            above: "OPERATING TEMPERATURE",
            under: "5° (MIN), 40° (MAX)",
          },
          {
            above: "STORAGE TEMPERATURE",
            under: "° (MIN ), ° (MAX)",
          },
          {
            above: "OPERATING LOW TEMPERATURE",
            under: "° (MIN), ° (MAX)",
          },
          {
            above: "IP RATING",
            under: "IP54 (IEC 60529)",
          },
        ],
      },
      {
        section: "NAVIGATION SENSORS",
        stats: [
          {
            above: "VISUAL INERTIAL SYSTEM",
            under: "Yes",
          },
          {
            above: "TILT",
            under: "IMU",
          },

          {
            above: "ALTIMETER",
            under: "",
          },
          {
            above: "COMPASS",
            under: "",
          },
          {
            above: "GNSS",
            under: "",
          },
        ],
      },
      {
        section: "ACCURACY",
        stats: [
          {
            above: "ANGULAR",
            under: "",
          },
          {
            above: "RANGE",
            under: "",
          },
          {
            above: "3D POINT ACCURACY",
            under: "@ 10m, @ 20m, @ 40m",
          },
          {
            above: "EXTERNAL",
            under: "",
          },
        ],
      },
      {
        section: "DATA PROCESSING",
        stats: [
          {
            above: "DATA TRANSFER",
            under: "",
          },
          {
            above: "DESKTOP SOFTWARE",
            under: "",
          },
          {
            above: "DESKTOP SOFTWARE",
            under: "",
          },
          {
            above: "EXTERNAL",
            under: "",
          },
        ],
      },
    ],
  },
  datasets: [
    "https://blk2021.com/wp-content/uploads/2021/10/BLK2FLY_SpecSheet-1.pdf",
    "https://blk2021.com/wp-content/uploads/2021/10/BLK2FLY_SpecSheet-1.pdf",
  ],

  ordering: [
    {
      title: "Ready to learn more?",
      paragraph:
        "Our EXPERTS@R-E-A-L.iT are ready to help you with onboarding this new technology in your business. Whether you need to train your employees with this new hardware, or have questions about what software solutions to use in the office to get the most out of your data, we are ready to assist you at every step of the process. Check out our coaching plans here. Still have questions? Click HERE to start a conversation with one of our experts.",
      buttonText: "TALK TO AN EXPERT@R-E-A-L.iT",
    },
    {
      title: "Ready to order?",
      paragraph:
        "Laser scanning is a revolutionary way to supercharge your workflow. At R-E-A-L.iT, we can help you increase the quality of your deliverables while also helping you work faster and smarter. The BLK2GO is a simple, sleek, and lightning fast way to gather measurements, helping your teams get in and out of sites in record time, with more data than they could ever capture using traditional measurement techniques. Ready to buy? Click HERE to place an order.",
      buttonText: "ORDER NOW",
    },
  ],
  isUnboxing:
    "initial" /* if there is an unboxing section, put "initial", otherwise put "none" */,
};
export default BLK2GO;
