import React from "react";

const Coming = () => {
  return (
    <div
      className="coming-soon"
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ width: "80vw" }}>
        <h1>Coming soon.</h1>
        <p>
          This page is not yet ready. Please check back later or contact us at
          EXPERTS@R-E-A-L.iT.
        </p>
      </div>
    </div>
  );
};

export default Coming;
