const BLK2FLY = {
  headerItems: [
    "overview",
    "technology",
    "use cases",
    "specifications",
    "request a demo",
  ],
  landing: {
    productName: "BLK2FLY",
    videoLink: "https://player.vimeo.com/video/605831860?background=1",
    subHeader: "AERIAL REALITY CAPTURE",
  },
  advertise: {
    title: "",
    videoLink: "",
    paragraph: "NOT STARTED",
  },
  sketchfab: {
    gradientColor: "#161616",
    modelLink:
      "https://sketchfab.com/models/546cd9a3fc364c46b842a72f20766a11/embed?camera=0&preload=1&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_watermark_link=0&ui_watermark=0&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&ui_color=00fb02&scrollwheel=0",
  },
  videosActive: false,
  videoSections: [
    {
      active: true,
      id: "PRESENTING",
      title: "NOT STARTED",
      video: "",
      paragraph: "",
    },
    {
      active: true,
      id: "USING",
      title: "NOT STARTED",
      video: "",
      paragraph: "",
    },
    {
      active: true,
      id: "DEMONSTRATING",
      title: "NOT STARTED",
      video: "",
      paragraph: "",
    },
    {
      active: true,
      id: "UNBOXING",
      title: "NOT STARTED",
      video: "",
      paragraph: "",
    },
  ],
  features: [
    {
      title: "TECHNOLOGY",
      topVideo: true,
      mediaLink: "https://player.vimeo.com/video/683052527?background=1",
      sections: [
        {
          title: "Autonomous UAV Scanning",
          image:
            "https://blk2021.com/wp-content/uploads/2021/09/BLK2FLY-UAV-1.jpg",
          paragraph:
            "The BLK2FLY intelligently incorporates the environment around it into its flight plans. It keeps the correct distance for ideal scanning and formulates optimized flight routes for return to home.",
        },
        {
          title: "Full Dome LiDAR Sensor",
          image:
            "https://blk2021.com/wp-content/uploads/2021/09/Full_LiDARDome.png",
          paragraph:
            "The enclosed, full dome laser scanner onboard the BLK2FLY captures 3D point clouds in all directions.",
        },
        {
          title: "GrandSLAM",
          image:
            "https://blk2021.com/wp-content/uploads/2021/08/BLK2FLY-GrandSlam.jpg",
          paragraph:
            "BLK2FLY takes GrandSLAM technology even further. LiDAR SLAM, Visual SLAM, radar, and GNSS make the BLK2FLY fully autonomous, easy to use, and able to fly safely and accurately on its own.",
        },
        {
          title: "Complete scanning with advanced obstacle avoidance",
          image:
            "https://blk2021.com/wp-content/uploads/2021/08/BLK2FLY-Object_Avoidance.jpg",
          paragraph:
            "BLK2FLY uses omnidirectional radar and LiDAR to avoid obstacles for accurate, uninterrupted, and safe scanning.",
        },
      ],
    },
    {
      title: "USE CASES",
      topVideo: true,
      mediaLink: "https://player.vimeo.com/video/683054909?background=1",
      sections: [
        {
          title: "Construction",
          image:
            "https://blk2021.com/wp-content/uploads/2021/08/BLK2FLY_Construction-site.jpg",
          paragraph:
            "BLK2FLY helps you easily document your construction progress, every step of the way, by saving time, gaining accuracy, and achieving better LiDAR coverage with an autonomous UAV scanning workflow.",
        },
        {
          title: "BIM",
          image:
            "https://blk2021.com/wp-content/uploads/2021/08/BLK2FLY_BIM.jpg",
          paragraph:
            "BLK2FLY will autonomously scan the entire exterior of a building from the air with high degrees of accuracy to gather BIM data, and it only requires minimal training to operate.",
        },

        {
          title: "Architecture",
          image:
            "https://blk2021.com/wp-content/uploads/2021/09/BLK2FLY-Bank.jpg",
          paragraph:
            "Easily document as-built conditions for retrofits and redesigns with the BLK2FLY, which offers an autonomous yet simple method for drone-based reality capture.",
        },
        {
          title: "UAV Mapping",
          image:
            "https://blk2021.com/wp-content/uploads/2021/09/BLK2FLY_UAV.jpg",
          paragraph:
            "The BLK2FLY integrates all UAV systems including LiDAR and Visual SLAM, radar, and GNSS with the ability to autonomously scan—and it’s a portable, easy to use device.",
        },
      ],
    },
  ],
  statistics: {
    specsTitle: "Specifications",
    specsVideo:
      "https://player.vimeo.com/video/500181508?background=1&autoplay=1",
    smallTitle: "Get the specs.",
    statsImage:
      "https://shop.leica-geosystems.com/sites/default/files/inline-images/BLK2GO-measurement-drawing_v2_0.png",
    statsItems: [
      {
        section: "DESIGN & PHYSICAL",
        stats: [
          {
            above: "HOUSING",
            under: "Carbon and glass fiber frame",
          },
          {
            above: "",
            under: "Width: 530 mm (20.87 in.), Height: 600 mm (23.62 in.)",
          },
          {
            above: "WEIGHT",
            under: "g (2.6 kgg including battery)",
          },
          {
            above: "MOUNTING",
            under: "N/A",
          },
        ],
      },
      {
        section: "OPERATION",
        stats: [
          {
            above: "ON SCANNER",
            under: "N/A",
          },
          {
            above: "CONTROL",
            under:
              "BLK2FLY Live App for iPad supporting LTE connectivity (iOS 13 or later). Including: flight planning, live 2D and 3D visualization, device satus, and data management",
          },
          {
            above: "WIRELESS",
            under: "LTE Bluetooth LE",
          },
          {
            above: "DATA STORAGE",
            under: "256 GB",
          },
        ],
      },
      {
        section: "POWER",
        stats: [
          {
            above: "INTERNAL",
            under: "Li-ion 14.8 V, 6.75 Ah, 99.9 Wh",
          },
          {
            above: "EXTERNAL",
            under: "N/A",
          },
        ],
      },
      {
        section: "SCANNING",
        stats: [
          {
            above: "DISTANCE MEASUREMENT",
            under: "830 nm wavelength",
          },
          {
            above: "LASER CLASS",
            under: "1 (in accordance with IEC 60825-1)",
          },
          {
            above: "FIELD OF VIEW",
            under: "270°C (HORIZONTAL), 360°C (VERTICAL)",
          },
          {
            above: "RANGE",
            under: "0.5m (MIN), 25m (MAX)",
          },
          {
            above: "SPEED",
            under: "420,000 pts/sec",
          },
          {
            above: "DENSITY",
            under: "Based on capture speed",
          },
        ],
      },
      {
        section: "IMAGING",
        stats: [
          {
            above: "CAMERA",
            under: "5-camera system, 1.6 MP, 300° x 180° total, global shutter",
          },
          {
            above: "SPEED",
            under: "Continuous",
          },

          {
            above: "HDR",
            under: "N/A",
          },
          {
            above: "THERMAL",
            under: "N/A",
          },
        ],
      },
      {
        section: "ENVIRONMENTAL",
        stats: [
          {
            above: "OPERATING TEMPERATURE",
            under: "5° (MIN), 35° (MAX)",
          },
          {
            above: "STORAGE TEMPERATURE",
            under: "-10° (MIN ), 60° (MAX)",
          },

          {
            above: "IP RATING",
            under: "IP54",
          },
        ],
      },
      {
        section: "NAVIGATION SENSORS",
        stats: [
          {
            above: "VISUAL INERTIAL SYSTEM",
            under: "",
          },
          {
            above: "TILT",
            under: "",
          },

          {
            above: "ALTIMETER",
            under: "",
          },
          {
            above: "COMPASS",
            under: "",
          },
          {
            above: "GNSS",
            under: "",
          },
        ],
      },
      {
        section: "ACCURACY",
        stats: [
          {
            above: "ANGULAR",
            under: "",
          },
          {
            above: "RANGE",
            under: "",
          },

          {
            above: "3D POINT ACCURACY",
            under: "@ 10m, @ 20m, @ 50m",
          },
        ],
      },
      {
        section: "DATA PROCESSING",
        stats: [
          {
            above: "DATA TRANSFER",
            under: "Wireless (WLAN & LTE) and USB 3.1 (USB C)",
          },
          {
            above: "DESKTOP SOFTWARE",
            under:
              "Cyclone REGISTER, Cyclone REGISTER 360, Cyclone REGISTER 360 BLK Edition",
          },
        ],
      },
    ],
  },
  datasets: [
    "https://blk2021.com/wp-content/uploads/2021/10/BLK2FLY_SpecSheet-1.pdf",
    "https://blk2021.com/wp-content/uploads/2021/10/BLK2FLY_SpecSheet-1.pdf",
  ],

  ordering: [
    {
      title: "Ready to learn more?",
      paragraph:
        "Our EXPERTS@R-E-A-L.iT are ready to help you with onboarding this new technology in your business. Whether you need to train your employees with this new hardware, or have questions about what software solutions to use in the office to get the most out of your data, we are ready to assist you at every step of the process. Check out our coaching plans at https://r-e-a-l.it/services/coach. Still have questions? Click the button below to start a conversation with one of our experts.",
      buttonText: "TALK TO AN EXPERT@R-E-A-L.iT",
    },
    {
      title: "Ready to order?",
      paragraph:
        "Laser scanning is a revolutionary way to supercharge your workflow. At R-E-A-L.iT, we can help you increase the quality of your deliverables while also helping you work faster and smarter. Our hardware provides you with a lightning fast way to gather measurements, helping your teams get in and out of sites in record time, with more data than they could ever capture using traditional measurement techniques. Ready to buy? Click the button below to place an order.",
      buttonText: "ORDER NOW",
    },
  ],
};
export default BLK2FLY;
