const PSERIES = {
  headerItems: [
    "overview",
    "technology",
    "use cases",
    "specifications",
    "request a demo",
  ],
  landing: {
    productName: "P-SERIES",
    videoLink: "https://player.vimeo.com/video/686071876?background=1",
    subHeader: "TERRESTRIAL REALITY CAPTURE",
  },
  advertise: {
    active: true,
    title: "Your Professional Scanning Solution.",
    videoLink: "https://www.youtube.com/embed/b38XxZQpunU",
    paragraph:
      "The most demanding and complex tasks call for the most powerful and reliable tools. The P-Series scanner from Leica Geosystems has everything you need to tackle the most difficult of scanning jobs. ",
  },
  sketchfab: {
    gradientColor: "#161616",
    modelLink:
      "https://sketchfab.com/models/33efc9c8f2204cadb64de29b6e08fb85/embed?autospin=1&autostart=1&ui_infos=0&ui_controls=0&ui_stop=0",
  },
  videosActive: true,
  videoSections: [
    {
      active: true,
      id: "PRESENTING",
      title: "Peak Performance and Repeatability - Every Time",
      video: "https://www.youtube.com/embed/j6aMFBKzsqs",
      paragraph:
        "P-Series scanners deliver in every situation. Over long distances and in almsot any weather conditions, this line of scanners is tough enough to keep you working while delivering high quality, realiable, accurate, precise, and repeatable data. Reducing downtime, these extremely durable 3D laser scanners perform even under the toughest environmental conditions, such as extreme temperatures ranging from – 20°C to + 50°C and comply with the IP54 rating for dust and water resistance. ",
    },
    {
      active: true,
      id: "USING",
      title: "The Perfect Partner for Any Project",
      video: "https://www.youtube.com/embed/WnfGGirvaCU",
      paragraph:
        "Leica ScanStation P-Series sacnners deliver highest quality 3D data and High-Dynamic Range (HDR) imaging at an extremely fast scan rate of 1 million points per second at ranges of up to 270m. Unsurpassed range and angular accuracy paired with low range noise and survey-grade dual-axis compensation form the foundation for highly detailed 3D colour point clouds mapped in realistic clarity.",
    },
    {
      active: true,
      id: "DEMONSTRATING",
      title: "Deliver Every Detail and Exceed Expectations",
      video: "https://www.youtube.com/embed/vwMU3phGdSE",
      paragraph:
        "Whether you need a detailed as-built representation of a façade, a 2D floor plan, 3D data for integration into Building Information Modelling (BIM), capturing 3D geometry of roads, rails, tunnels and bridges or high-definition scan data for topographic maps and as-built surveys, you know you’ll need an accurate long range scanning tool for your projects – the ScanStation P-Series laser scanners from Leica Geosystems are the right choice, because every detail matters.",
    },
    {
      active: true,
      id: "UNBOXING",
      title: "Everything You Need to Revolutionize Your Workflow",
      video: "https://www.youtube.com/embed/32GK7EGX2JU",
      paragraph:
        "With Leica's complete P-Series solutions, jobs that used to take months can now take only hours. Work faster than every before while gathering higher quality data than previously possible, all while remaining at a safe distance from crime scenes, dangerous equipment, or extreme heights. LEica's P-Series solutions are adaptable to your needs and reliably accurate- every time. ",
    },
  ],
  features: [
    {
      title: "TECHNOLOGY",
      topVideo: true,
      mediaLink: "",
      sections: [
        {
          title: "Long Range Terrestrial Scanning",
          image: "",
          paragraph:
            "With the greatest possible ranges in the industry, your P-Series scanner allows you to scan anywhere, safely and accurately. ",
        },
        {
          title: "Tough and durable",
          image: "",
          paragraph:
            "With operating temperature ranges from -20 to 50 degrees celsius and IP54 rating for dust and water resistance, the P-Series can go wherever you can go. ",
        },
        {
          title: "Extremely fast scanninng",
          image: "",
          paragraph:
            "Gather 1 milion points per second even at ranges of over 1km with the P50. ",
        },
        {
          title: "Dual-axis compensation for accuracy at long range",
          image: "",
          paragraph:
            "Unsurpassed range and angular accuracy paired with low range noise and survey-grade dual-axis compensation form the foundation for highly detailed 3D colour point clouds mapped in realistic clarity.",
        },
      ],
    },
    {
      title: "USE CASES",
      topVideo: true,
      mediaLink: "",
      sections: [
        {
          title: "",
          image: "",
          paragraph: "",
        },
        {
          title: "",
          image: "",
          paragraph: "",
        },

        {
          title: "",
          image: "",
          paragraph: "",
        },
        {
          title: "",
          image: "",
          paragraph: "",
        },
        {
          title: "",
          image: "",
          paragraph: "",
        },
      ],
    },
  ],
  statistics: {
    specsTitle: "Specifications",
    specsVideo:
      "https://player.vimeo.com/video/500181508?background=1&autoplay=1",
    smallTitle: "Get the specs.",
    statsImage:
      "https://shop.leica-geosystems.com/sites/default/files/inline-images/BLK2GO-measurement-drawing_v2_0.png",
    statsItems: [
      {
        section: "DESIGN & PHYSICAL",
        stats: [
          {
            above: "HOUSING",
            under: "",
          },
          {
            above: "",
            under: "Width: 358 mm (14.09 in.), Height: 395 mm (15.55 in.)",
          },
          {
            above: "WEIGHT",
            under: "12.25 kgg (13.05 kgg including battery)",
          },
          {
            above: "MOUNTING",
            under: "Upright or inverted",
          },
        ],
      },
      {
        section: "OPERATION",
        stats: [
          {
            above: "ON SCANNER",
            under: "Full colour touchscreen for onboard scan control.",
          },
          {
            above: "CONTROL",
            under:
              "Leica Cyclone FIELD 360 app for iPad or Android tablets including: Remote control of scan functions, 2D & 3D data viewing, Tagging, Automatic alignment of scans Remote control: Leica CS10/CS15 controller or any other remote desktop capable device, including iPad, iPhone and other SmartPhones; external simulator.",
          },
          {
            above: "WIRELESS",
            under: "Integrated wireless",
          },
          {
            above: "DATA STORAGE",
            under:
              "256GB internal solid-state drive (SSD) or external USB device",
          },
        ],
      },
      {
        section: "POWER",
        stats: [
          {
            above: "INTERNAL",
            under: "2× Internal: Li-Ion 5.5h",
          },
          {
            above: "EXTERNAL",
            under:
              "External > 7.5h (room temp.) External: Li-Ion (connect via external port, simultaneous use, hot swappable)",
          },
        ],
      },
      {
        section: "SCANNING",
        stats: [
          {
            above: "DISTANCE MEASUREMENT",
            under:
              "Ultra-high speed time-of-flight enhanced by Waveform Digitising (WFD) technology, 1550nm (invisible) / 658nm (visible) wavelength",
          },
          {
            above: "LASER CLASS",
            under: "1 (in accordance with IEC 60825:2014)",
          },
          {
            above: "FIELD OF VIEW",
            under: "360°C (HORIZONTAL), 290°C (VERTICAL)",
          },
          {
            above: "RANGE",
            under: "0.4m (MIN), 1170m (MAX)",
          },
          {
            above: "SPEED",
            under: "Up to 1,000,000 pts/sec",
          },
          {
            above: "DENSITY",
            under:
              "7 user selectable settings (0.8/1.6/3.1/6.3/12.7/25/50mm @10m)",
          },
        ],
      },
      {
        section: "IMAGING",
        stats: [
          {
            above: "CAMERA",
            under:
              "4 megapixels per each 17°×17° colour image; 700 megapixels for panoramic image. Optional external camera (Canon EOS 60D/70D/80D supported)",
          },
          {
            above: "SPEED",
            under: "Min 4 minutes for full-dome",
          },

          {
            above: "HDR",
            under: "3 brackets",
          },
          {
            above: "THERMAL",
            under: "N/A",
          },
        ],
      },
      {
        section: "ENVIRONMENTAL",
        stats: [
          {
            above: "OPERATING TEMPERATURE",
            under: "–20° (MIN), 50° (MAX)",
          },
          {
            above: "STORAGE TEMPERATURE",
            under: "–40° (MIN ), 70° (MAX)",
          },

          {
            above: "IP RATING",
            under: "Solid particle/liquid ingress protection IP54 (IEC 60529)",
          },
        ],
      },
      {
        section: "NAVIGATION SENSORS",
        stats: [
          {
            above: "VISUAL INERTIAL SYSTEM",
            under: "",
          },
          {
            above: "TILT",
            under:
              "Dual-axis compensator. Liquid sensor with real-time onboard compensation, selectable on/off, resolution 1”, dynamic range ±5’, accuracy 1.5”",
          },

          {
            above: "ALTIMETER",
            under: "",
          },
          {
            above: "COMPASS",
            under: "",
          },
          {
            above: "GNSS",
            under: "",
          },
        ],
      },
      {
        section: "ACCURACY",
        stats: [
          {
            above: "ANGULAR",
            under: "8” horizontal; 8” vertical",
          },
          {
            above: "RANGE",
            under: "0.4mm rms at 10m, 0.5mm rms at 50m",
          },

          {
            above: "3D POINT ACCURACY",
            under: "1.6 mm@ 10m, 1.9 mm@ 20m, 3 mm@ 50m",
          },
        ],
      },
      {
        section: "DATA PROCESSING",
        stats: [
          {
            above: "DATA TRANSFER",
            under:
              "Gigabit Ethernet, integrated Wireless LAN or USB 2.0 device",
          },
          {
            above: "DESKTOP SOFTWARE",
            under: "Cyclone REGISTER, Cyclone REGISTER 360",
          },
        ],
      },
    ],
  },
  datasets: [
    "https://blk2021.com/wp-content/uploads/2021/10/BLK2FLY_SpecSheet-1.pdf",
    "https://blk2021.com/wp-content/uploads/2021/10/BLK2FLY_SpecSheet-1.pdf",
  ],

  ordering: [
    {
      title: "Ready to learn more?",
      paragraph:
        "Our EXPERTS@R-E-A-L.iT are ready to help you with onboarding this new technology in your business. Whether you need to train your employees with this new hardware, or have questions about what software solutions to use in the office to get the most out of your data, we are ready to assist you at every step of the process. Check out our coaching plans here. Still have questions? Click HERE to start a conversation with one of our experts.",
      buttonText: "TALK TO AN EXPERT@R-E-A-L.iT",
    },
    {
      title: "Ready to order?",
      paragraph:
        "Laser scanning is a revolutionary way to supercharge your workflow. At R-E-A-L.iT, we can help you increase the quality of your deliverables while also helping you work faster and smarter. The BLK2GO is a simple, sleek, and lightning fast way to gather measurements, helping your teams get in and out of sites in record time, with more data than they could ever capture using traditional measurement techniques. Ready to buy? Click HERE to place an order.",
      buttonText: "ORDER NOW",
    },
  ],
};
export default PSERIES;
