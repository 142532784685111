const RTC360 = {
  headerItems: [
    "overview",
    "technology",
    "use cases",
    "specifications",
    "request a demo",
  ],
  landing: {
    productName: "RTC360",
    videoLink: "https://player.vimeo.com/video/686072699?background=1",
    subHeader: "TERRESTRIAL REALITY CAPTURE",
  },
  advertise: {
    active: true,
    title: "Position yourself for success",
    videoLink: "https://www.youtube.com/embed/1adLSP-QRIo",
    paragraph:
      "The RTC360 from Leica Geosystems is an amazing scanner that combines portability, precision, and speed in a beautiful compact package.",
  },
  sketchfab: {
    gradientColor: "#161616",
    modelLink:
      "https://sketchfab.com/models/51b03aca748148b18e2196e90a4080f7/embed?camera=0&preload=1&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_watermark_link=0&ui_watermark=0&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&ui_color=db0d0d&amp;scrollwheel=0",
  },
  videosActive: true,
  videoSections: [
    {
      active: true,
      id: "PRESENTING",
      title: "Dominate your market",
      video: "https://www.youtube.com/embed/_Ba3lI6wvDI",
      paragraph:
        "Jam-packed with powerful features to help you gather measurements with precision, ease, and speed, the RTC360 is a key tool in dominating your competition and maximizing your productivity. Check out this video that highlights the technical features that make the RTC360 such an astounding and innovative tool.",
    },
    {
      active: true,
      id: "USING",
      title: "Maximize your time",
      video: "https://www.youtube.com/embed/Nippub1h80w",
      paragraph:
        "The RTC360 empowers users to document and capture their environments in 3D, improving efficiency and productivity in the field with speed, ease-of-use, and portability even on the most complex of projects.",
    },
    {
      active: true,
      id: "DEMONSTRATING",
      title: "Increase your productivity",
      video: "https://www.youtube.com/embed/jdM9DjNb9LU",
      paragraph:
        "No matter what you need to scan, the RTC360 can get you reliable data in less time than ever before. Small and lightweight, the Leica RTC360 scanner’s portable design and collapsible tripod mean it’s compact enough to fit into most backpacks, ready to be taken anywhere. Once on-site, easy-to-use, one-button operation makes for fast, hassle-free scanning.",
    },
    {
      active: true,
      id: "UNBOXING",
      title: "Ready to go - straight out of the box",
      video: "https://www.youtube.com/embed/mt4BpVuXemU",
      paragraph:
        "Check out everything included in the box when you purchase an RTC360. With this amazing little scanner, you can be up and running to maximize your productivity in no time flat.",
    },
  ],
  features: [
    {
      title: "TECHNOLOGY",
      topVideo: true,
      mediaLink: "",
      sections: [
        {
          title: "GrandSLAM technology",
          image: "",
          paragraph:
            "The combination of visual and lidar SLAM technology sets the RTC 360 apart from its competition, and allows the scanner to track its location from one setup to another. ",
        },
        {
          title: "Create 3D Colored Point Clouds",
          image: "",
          paragraph:
            "With a measuring rate of up to 2 million points per second and advanced HDR imaging system, the creation of coloured 3D point clouds can be completed in under two minutes.",
        },
        {
          title: "VIS technology",
          image: "",
          paragraph:
            "Automated targetless field registration and the seamless, automated transfer of data from site to office reduce time spent in the field and further maximise productivity.",
        },
        {
          title: "Pre-registraton in the field",
          image: "",
          paragraph:
            "As part of the RTC360 solution, the Cyclone FIELD 360 app links the 3D data acquisition in the field with the laser scanner and data registration in the office with Cyclone REGISTER 360. On-site the user can automatically capture, register and examine scan and image data. The user interface combines easy handling of complex calculations with a graphical user guidance that offers a remarkable user experience, also for novice users.",
        },
      ],
    },
    {
      title: "USE CASES",
      topVideo: true,
      mediaLink: "",
      sections: [
        {
          title: "Publc Safety",
          image: "",
          paragraph:
            "When documenting the scene of accident, police can capture accurate mapping of crash and collisions at record speed with the RTC360. Limit interruptions to traffic and infrastructure by using advanced laser scanning technology that allows officers to quickly doucment millions of measurements for further investigation and evaluation from the office. Keep officers safely out of traffic by gathering data from a safe distance with the RTC360. ",
        },
        {
          title: "Archeology and Preservation",
          image: "",
          paragraph:
            "From archeological digs to historical restoration work, accurate replicas of existing conditions are key to achieving high quality results, with lasting effects for generations to come. The RTC360 is a non-invasive way to documents site conditions, collect measurements, and gather data for generations to come. ",
        },

        {
          title: "Construction",
          image: "",
          paragraph:
            "From planning to building to BIM, Leica's RTC360 enables construction professionals to manage every step of their project with precision, speed, and accuracy. The RTC360's GrandSLAM technology makes on site scanning faster and safer than ever before, and the pre-registration of point clouds in the field saves you immense time back at your office as you work with the data you've collected on site. ",
        },
        {
          title: "Surveying",
          image: "",
          paragraph:
            "Survey professionals have impeccably high standards. The RTC360 can help you to deliver amazing results to your clients in record time. ",
        },
        {
          title: "",
          image: "",
          paragraph: "",
        },
      ],
    },
  ],
  statistics: {
    specsTitle: "Specifications",
    specsVideo:
      "https://player.vimeo.com/video/500181508?background=1&autoplay=1",
    smallTitle: "Get the specs.",
    statsImage:
      "https://shop.leica-geosystems.com/sites/default/files/inline-images/BLK2GO-measurement-drawing_v2_0.png",
    statsItems: [
      {
        section: "DESIGN & PHYSICAL",
        stats: [
          {
            above: "HOUSING",
            under: "Aluminium frame and sidecovers",
          },
          {
            above: "",
            under: "Width: 240 mm (9.45 in.), Height: 230 mm (9.06 in.)",
          },
          {
            above: "WEIGHT",
            under: "5.35 kgg (6.03 kgg including battery)",
          },
          {
            above: "MOUNTING",
            under:
              "Quick mounting on 5/8“ stub on lightweight tripod / optional tribrach adapter / survey tribrach adapter available",
          },
        ],
      },
      {
        section: "OPERATION",
        stats: [
          {
            above: "ON SCANNER",
            under:
              "Touch-screen control with finger touch, full colour WVGA graphic display 480 x 800 pixels",
          },
          {
            above: "CONTROL",
            under:
              "Leica Cyclone FIELD 360 app for iPad or Android tablets including: Remote control of scan functions, 2D & 3D data viewing, Tagging, Automatic alignment of scans",
          },
          {
            above: "WIRELESS",
            under: "Integrated wireless LAN (802.11 b/g/n)",
          },
          {
            above: "DATA STORAGE",
            under: "Leica MS256, 256GB exchangeable USB 3.0 flash drive",
          },
        ],
      },
      {
        section: "POWER",
        stats: [
          {
            above: "INTERNAL",
            under:
              "2 x Leica GEB361 internal, rechargeable Li-Ion batteries. Duration: Typically up to 4 hours Weight: 340 g per battery",
          },
          {
            above: "EXTERNAL",
            under: "Leica GEV282 AC adapter",
          },
        ],
      },
      {
        section: "SCANNING",
        stats: [
          {
            above: "DISTANCE MEASUREMENT",
            under:
              "High-speed, high dynamic time of flight enhanced by Waveform Digitizing (WFD) technology, 1550 nm (invisible) wavelength",
          },
          {
            above: "LASER CLASS",
            under: "1 (in accordance with IEC 60825-1:2014)",
          },
          {
            above: "FIELD OF VIEW",
            under: "360°C (HORIZONTAL), 300°C (VERTICAL)",
          },
          {
            above: "RANGE",
            under: "0.5m (MIN), 130m (MAX)",
          },
          {
            above: "SPEED",
            under: "Up to 2,000,000 pts/sec",
          },
          {
            above: "DENSITY",
            under: "3 user selectable settings (3/6/12mm @ 10m)",
          },
        ],
      },
      {
        section: "IMAGING",
        stats: [
          {
            above: "CAMERA",
            under:
              "36 MP 3-camera system captures 432 MPx raw data for calibrated 360° x 300° spherical image",
          },
          {
            above: "SPEED",
            under:
              "1 minute for full spherical HDR image at any light condition",
          },

          {
            above: "HDR",
            under: "Automatic, 5 brackets",
          },
          {
            above: "THERMAL",
            under: "N/A",
          },
        ],
      },
      {
        section: "ENVIRONMENTAL",
        stats: [
          {
            above: "OPERATING TEMPERATURE",
            under: "-10° (MIN), 40° (MAX)",
          },
          {
            above: "STORAGE TEMPERATURE",
            under: "-40° (MIN ), 70° (MAX)",
          },

          {
            above: "IP RATING",
            under: "Solid particle/liquid ingress protection IP54 (IEC 60529)",
          },
        ],
      },
      {
        section: "NAVIGATION SENSORS",
        stats: [
          {
            above: "VISUAL INERTIAL SYSTEM",
            under:
              "Video enhanced inertial measuring system to track movement of the scanner position relative to the previous setup in real time",
          },
          {
            above: "TILT",
            under:
              "IMU based, Accuracy: 18“ (for upright and upside down setups with +/- 5° inclination)",
          },

          {
            above: "ALTIMETER",
            under: "Yes",
          },
          {
            above: "COMPASS",
            under: "Yes",
          },
          {
            above: "GNSS",
            under: "Yes",
          },
        ],
      },
      {
        section: "ACCURACY",
        stats: [
          {
            above: "ANGULAR",
            under: "18",
          },
          {
            above: "RANGE",
            under: "1.0 mm + 10 ppm",
          },

          {
            above: "3D POINT ACCURACY",
            under: "1.9 mm@ 10m, 2.9 mm@ 20m, 6.5 mm@ 50m",
          },
        ],
      },
      {
        section: "DATA PROCESSING",
        stats: [
          {
            above: "DATA TRANSFER",
            under: "USB, Ethernet, Wireless",
          },
          {
            above: "DESKTOP SOFTWARE",
            under: "Cyclone REGISTER, Cyclone REGISTER 360",
          },
        ],
      },
    ],
  },
  datasets: [
    "https://blk2021.com/wp-content/uploads/2021/10/BLK2FLY_SpecSheet-1.pdf",
    "https://blk2021.com/wp-content/uploads/2021/10/BLK2FLY_SpecSheet-1.pdf",
  ],

  ordering: [
    {
      title: "Ready to learn more?",
      paragraph:
        "Our EXPERTS@R-E-A-L.iT are ready to help you with onboarding this new technology in your business. Whether you need to train your employees with this new hardware, or have questions about what software solutions to use in the office to get the most out of your data, we are ready to assist you at every step of the process. Check out our coaching plans at https://r-e-a-l.it/services/coach. Still have questions? Click the button below to start a conversation with one of our experts.",
      buttonText: "TALK TO AN EXPERT@R-E-A-L.iT",
    },
    {
      title: "Ready to order?",
      paragraph:
        "Laser scanning is a revolutionary way to supercharge your workflow. At R-E-A-L.iT, we can help you increase the quality of your deliverables while also helping you work faster and smarter. Our hardware provides you with a lightning fast way to gather measurements, helping your teams get in and out of sites in record time, with more data than they could ever capture using traditional measurement techniques. Ready to buy? Click the button below to place an order.",
      buttonText: "ORDER NOW",
    },
  ],
};
export default RTC360;
