const BLKARC = {
  headerItems: [
    "overview",
    "technology",
    "use cases",
    "specifications",
    "request a demo",
  ],
  landing: {
    productName: "BLK ARC",
    videoLink: "https://player.vimeo.com/video/680416458?background=1",
    subHeader: "ROBOTIC REALITY CAPTURE",
  },

  advertise: {
    active: false,
    title: "Be Autonomous",
    videoLink: "Insert link to BLK ARC INTRODUCING video here.",
    paragraph:
      "The BLK ARC is a versatile laser scanning module that can work with multiple different types of robotic carriers, enabling you to gather data even in locations unfit for human occupation. From crime scenes to natural disasters, mine shafts to extreme landscapes, the BLK ARC is your partner in gathering reliable data safely and effectively.",
  },
  sketchfab: {
    gradientColor: "#161616",
    modelLink:
      "https://sketchfab.com/models/1ff5944fd1a3482288d8d37c137cde76/embed?autostart=1&internal=1&tracking=0&ui_infos=0&ui_snapshots=1&ui_stop=0&ui_watermark=0",
  },
  videosActive: false,
  videoSections: [
    {
      active: true,
      id: "PRESENTING",
      title: "Versatile, robust, and groundbreaking technology",
      video: "Insert link to BLK ARC PRESENTING video here.",
      paragraph:
        "The BLK ARC module adapts to any setting, and is compatible with many robotic carriers. Remote user operation, offline path planning, GrandSLAM technology, static and mobile scanning modes, and robust LiDAR module protection equip the BLKARC to get you what you need, safely and securely, in any setting. ",
    },
    {
      active: true,
      id: "USING",
      title: "Unparalleled safety and security",
      video: "Insert link to BLK ARC USING video here.",
      paragraph:
        "The BLK ARC module adapts to any setting, and is compatible with many robotic carriers. Remote user operation, offline path planning, GrandSLAM technology, static and mobile scanning modes, and robust LiDAR module protection equip the BLKARC to get you what you need, safely and securely, in any setting. ",
    },
    {
      active: true,
      id: "DEMONSTRATING",
      title: "A host of features for every setting",
      video: "Insert link to BLK ARC DEMONSTRATING video here.",
      paragraph:
        "The BLK ARC is a complete scanning solution that will get the autonomous reovlution at work for you and your business. ",
    },
    {
      active: true,
      id: "UNBOXING",
      title: "Ready to get scanning for you",
      video: "Insert link to BLK ARC UNBOXING video here.",
      paragraph:
        "Speak with one of our experts to learn about what robotic carrier makes sense for your needs. BLK ARC and it's robotic carriers can go where humans could otherwise face danger and insecurity. Let the BLK ARC do what you can't, and take reality capture technology to every part of your job. ",
    },
  ],
  features: [
    {
      title: "TECHNOLOGY",
      topVideo: true,
      mediaLink: "https://player.vimeo.com/video/680429434?background=1",
      sections: [
        {
          title: "Remote user operation",
          image: "https://blk2021.com/wp-content/uploads/2021/09/RemoteOp.jpg",
          paragraph:
            "Plan and execute missions from a remote location, whether in the office or in the field, which also keeps you safe from hazardous areas.",
        },
        {
          title: "Offline path planning",
          image:
            "https://blk2021.com/wp-content/uploads/2021/09/OfflinePath.jpg",
          paragraph:
            "Create new missions and adjust existing missions for any environment using Leica Cyclone 3DR.",
        },
        {
          title: "GrandSLAM technology",
          image: "https://blk2021.com/wp-content/uploads/2021/09/GrandSlam.jpg",
          paragraph:
            "GrandSLAM combines LiDAR SLAM, Visual SLAM, and an IMU to enable fully autonomous scanning and robotic navigation.",
        },
        {
          title: "Static and mobile scanning modes",
          image: "https://blk2021.com/wp-content/uploads/2021/09/Static.jpg",
          paragraph:
            "BLK ARC can scan in static and mobile mode during the same scan mission.",
        },
      ],
    },
    {
      title: "USE CASES",
      topVideo: true,
      mediaLink: "https://player.vimeo.com/video/680436013?background=1",
      sections: [
        {
          title: "Buildings & Infrastructure",
          image:
            "https://blk2021.com/wp-content/uploads/2021/09/BLKARC_white-room-scaled.jpg",
          paragraph:
            "Documenting site conditions on a daily, routine basis can be time-consuming and repetitive, but BLK ARC makes the job easy, fast, and fully autonomous.",
        },
        {
          title: "Underground",
          image:
            "https://blk2021.com/wp-content/uploads/2021/09/BLKARC_TrainTunnel.jpg",
          paragraph:
            "To document structures and environments in hazardous or inconvenient environments, like underground mines, BLK ARC is the tool of choice as you can plan scans remotely and let it scan on its own.",
        },

        {
          title: "Industrial & Plant",
          image:
            "https://blk2021.com/wp-content/uploads/2021/09/BLKARC_EmptyRoom.jpg",
          paragraph:
            "To perform reality capture of complex structures and environments, BLK ARC is ideal; with SPOT, it autonomously scans and navigates through plants and facilities.",
        },
        {
          title: "Utilities",
          image:
            "https://blk2021.com/wp-content/uploads/2021/09/BLKARC_utilities.jpg",
          paragraph:
            "Capturing utility infrastructure, complexes, and facilities with BLK ARC provides an easy to use and fully autonomous robotic reality capture workflow with minimal hands-on work required.",
        },
      ],
    },
  ],
  statistics: {
    specsTitle: "Specifications",
    specsVideo:
      "https://player.vimeo.com/video/500181508?background=1&autoplay=1",
    smallTitle: "Get the specs.",
    imageActive: false,
    statsImage: "",
    statsItems: [
      {
        section: "DESIGN & PHYSICAL",
        stats: [
          {
            above: "HOUSING",
            under: "Black powder coated aluminium",
          },
          {
            above: "",
            under: "Diameter: 80 mm (3.15 in.), Height: 183.6 mm (7.23 in.)",
          },
          {
            above: "WEIGHT",
            under: "690 gg (g including battery)",
          },
          {
            above: "MOUNTING",
            under:
              "Requires additional installation on a supported robotic carrier.",
          },
        ],
      },
      {
        section: "OPERATION",
        stats: [
          {
            above: "ON SCANNER",
            under: "N/A",
          },
          {
            above: "CONTROL",
            under: "Browser-based mission control user interface.",
          },
          {
            above: "WIRELESS",
            under: "Integrated wireless",
          },
          {
            above: "DATA STORAGE",
            under:
              "24 hours of scanning (compressed data) / 6 hours (uncompressed data)",
          },
        ],
      },
      {
        section: "POWER",
        stats: [
          {
            above: "INTERNAL",
            under: "N/A",
          },
          {
            above: "EXTERNAL",
            under: "From robotic platform",
          },
        ],
      },
      {
        section: "SCANNING",
        stats: [
          {
            above: "DISTANCE MEASUREMENT",
            under: "830 nm wavelength",
          },
          {
            above: "LASER CLASS",
            under: "1 (in accordance with IEC 60825-1)",
          },
          {
            above: "FIELD OF VIEW",
            under: "360°C (HORIZONTAL), 270°C (VERTICAL)",
          },
          {
            above: "RANGE",
            under: "0.5m (MIN), 25m (MAX)",
          },
          {
            above: "SPEED",
            under: "420,000 pts/sec",
          },
          {
            above: "DENSITY",
            under: "Based on capture speed",
          },
        ],
      },
      {
        section: "IMAGING",
        stats: [
          {
            above: "CAMERA",
            under: "12 Mpixel, 90 x 120, rolling shutter",
          },
          {
            above: "SPEED",
            under: "Continuous",
          },

          {
            above: "HDR",
            under: "N/A",
          },
          {
            above: "THERMAL",
            under: "N/A",
          },
        ],
      },
      {
        section: "ENVIRONMENTAL",
        stats: [
          {
            above: "OPERATING TEMPERATURE",
            under: "0° (MIN), 40° (MAX)",
          },
          {
            above: "STORAGE TEMPERATURE",
            under: "° (MIN ), ° (MAX)",
          },

          {
            above: "IP RATING",
            under: "",
          },
        ],
      },
      {
        section: "NAVIGATION SENSORS",
        stats: [
          {
            above: "VISUAL INERTIAL SYSTEM",
            under: "",
          },
          {
            above: "TILT",
            under: "",
          },

          {
            above: "ALTIMETER",
            under: "",
          },
          {
            above: "COMPASS",
            under: "",
          },
          {
            above: "GNSS",
            under: "",
          },
        ],
      },
      {
        section: "ACCURACY",
        stats: [
          {
            above: "ANGULAR",
            under: "",
          },
          {
            above: "RANGE",
            under: "",
          },

          {
            above: "3D POINT ACCURACY",
            under: "@ 10m, @ 20m, @ 50m",
          },
        ],
      },
      {
        section: "DATA PROCESSING",
        stats: [
          {
            above: "DATA TRANSFER",
            under: "Wireless and USB 3.0",
          },
          {
            above: "DESKTOP SOFTWARE",
            under:
              "Leica Cyclone REGISTER 360 and Cyclone REGISTER 360 (BLK Edition), HxDR cloud-bases platform",
          },
        ],
      },
    ],
  },
  datasets: [
    "https://blk2021.com/wp-content/uploads/2021/10/BLK2FLY_SpecSheet-1.pdf",
    "https://blk2021.com/wp-content/uploads/2021/10/BLK2FLY_SpecSheet-1.pdf",
  ],

  ordering: [
    {
      title: "Ready to learn more?",
      paragraph:
        "Our EXPERTS@R-E-A-L.iT are ready to help you with onboarding this new technology in your business. Whether you need to train your employees with this new hardware, or have questions about what software solutions to use in the office to get the most out of your data, we are ready to assist you at every step of the process. Check out our coaching plans at https://r-e-a-l.it/services/coach. Still have questions? Click the button below to start a conversation with one of our experts.",
      buttonText: "TALK TO AN EXPERT@R-E-A-L.iT",
    },
    {
      title: "Ready to order?",
      paragraph:
        "Laser scanning is a revolutionary way to supercharge your workflow. At R-E-A-L.iT, we can help you increase the quality of your deliverables while also helping you work faster and smarter. Our hardware provides you with a lightning fast way to gather measurements, helping your teams get in and out of sites in record time, with more data than they could ever capture using traditional measurement techniques. Ready to buy? Click the button below to place an order.",
      buttonText: "ORDER NOW",
    },
  ],
};
export default BLKARC;
