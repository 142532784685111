import React from "react";
import "./Error.css";
import errorImg from "./Error2.png";

import { NavLink } from "react-router-dom";

const Error = () => {
  return (
    <div id="error">
      <img src={errorImg}></img>
      {/* <div className="overlay">
        <h1>404</h1>
        <p>
          This page doesn't exist. Perhaps you'd like to go{" "}
          <a href="r-e-a-l.it/sitemap">home</a>, or view the sitemap{" "}
          <a href="r-e-a-l.it/sitemap">here</a>?
        </p>
      </div> */}
    </div>
  );
};

export default Error;
