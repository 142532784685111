const PEGASUS_BACKPACK = {
  headerItems: [
    "overview",
    "technology",
    "use cases",
    "specifications",
    "request a demo",
  ],
  landing: {
    productName: "PEGASUS:BACKPACK",
    videoLink: "Insert link to PEGASUS:BACKPACK OVERVIEW video here.",
  },
  advertise: {
    active: false,
    title: "Capture your surroundings",
    videoLink: "Insert link to PEGASUS:BACKPACK INTRODUCING video here.",
    paragraph:
      "Leica's Pegasus: Backpack is the award-winning wearable reality capture platform that collects data indoors, outdoors and underground.",
  },
  sketchfab: {
    gradientColor: "#161616",
    modelLink: "Insert link to PEGASUS:BACKPACK 3D MODEL 3D model here.",
  },
  videoSections: [
    {
      id: "PRESENTING",
      title: "Portable, secure, and reliable reality capture",
      video: "Insert link to PEGASUS:BACKPACK PRESENTING video here.",
      paragraph:
        "This unique mobile mapping solution is designed for rapid and regular reality capture. It is completely portable, enabling it to be checked in as luggage on a flight. The Pegasus:Backpack is designed to act a sensor platform with our standard external trigger and sync port outputs.",
    },
    {
      id: "USING",
      title: "Capture immersive 3D imagery just by walking",
      video: "Insert link to PEGASUS:BACKPACK USING video here.",
      paragraph:
        "The Leica Pegasus:Backpack is the award-winning wearable reality capture solution enabling you to make quick and informed decisions in fast-changing, challenging and hostile environments. Just by walking, this multidisciplinary system allows you to digitize pedestrianized city centres, the progress of construction sites, building interiors, underground structures and many more challenging applications.",
    },
    {
      id: "DEMONSTRATING",
      title: "Light and robust, the Pegasus: Backpack goes wherever you do",
      video: "Insert link to PEGASUS:BACKPACK DEMONSTRATING video here.",
      paragraph:
        "The lightweight carbon unibody system frame and the researched ergonomic support structure increases the mobility and flexibility for the wearer under challenging conditions, and reduces fatigue resulting in increased productivity.",
    },
    {
      id: "UNBOXING",
      title: "Mobile mapping - indoors, outdoors, even underground",
      video: "Insert link to PEGASUS:BACKPACK UNBOXING video here.",
      paragraph:
        "The Leica Pegasus:Backpack is an award-winning wearable reality capture sensor platform. A highly ergonomic design combines five cameras offering fully calibrated 360 degrees view and two LiDAR profilers with an ultra-light carbon fibre chassis. It enables extensive and efficient indoor or outdoor documentation at a level of accuracy that is authoritative and professional.",
    },
  ],
  features: [
    {
      title: "TECHNOLOGY",
      topVideo: true,
      mediaLink: "",
      sections: [
        {
          title: "",
          image: "",
          paragraph: "",
        },
        {
          title: "",
          image: "",
          paragraph: "",
        },
        {
          title: "",
          image: "",
          paragraph: "",
        },
        {
          title: "",
          image: "",
          paragraph: "",
        },
      ],
    },
    {
      title: "USE CASES",
      topVideo: true,
      mediaLink: "",
      sections: [
        {
          title: "",
          image: "",
          paragraph: "",
        },
        {
          title: "",
          image: "",
          paragraph: "",
        },

        {
          title: "",
          image: "",
          paragraph: "",
        },
        {
          title: "",
          image: "",
          paragraph: "",
        },
        {
          title: "",
          image: "",
          paragraph: "",
        },
      ],
    },
  ],
  statistics: {
    specsTitle: "Specifications",
    specsVideo:
      "https://player.vimeo.com/video/500181508?background=1&autoplay=1",
    smallTitle: "Get the specs.",
    statsImage:
      "https://shop.leica-geosystems.com/sites/default/files/inline-images/BLK2GO-measurement-drawing_v2_0.png",
    statsItems: [
      {
        section: "DESIGN & PHYSICAL",
        stats: [
          {
            above: "HOUSING",
            under: "Carbon Fibre",
          },
          {
            above: "",
            under: "Width: 530 mm (20.87 in.), Height: 950 mm (37.40 in.)",
          },
          {
            above: "WEIGHT",
            under: "10.3 kgg (11.9 kgg including battery)",
          },
          {
            above: "MOUNTING",
            under: "Backpack to mount on person",
          },
        ],
      },
      {
        section: "OPERATION",
        stats: [
          {
            above: "ON SCANNER",
            under: "N/A",
          },
          {
            above: "CONTROL",
            under: "Multi-core industrial PC, low power consumption",
          },
          {
            above: "WIRELESS",
            under:
              "Ethernet and wireless connections available. Service support available through remote connection.",
          },
          {
            above: "DATA STORAGE",
            under: "1 TB SSD hard disk with USB3 interface.",
          },
        ],
      },
      {
        section: "POWER",
        stats: [
          {
            above: "INTERNAL",
            under:
              "4 hrs (4 Batteries), Batteries are hot-swappable (no shut down needed)",
          },
          {
            above: "EXTERNAL",
            under: "N/A",
          },
        ],
      },
      {
        section: "SCANNING",
        stats: [
          {
            above: "DISTANCE MEASUREMENT",
            under: "903 nm wavelength",
          },
          {
            above: "LASER CLASS",
            under: "1 (in accordance with IEC 60825-1)",
          },
          {
            above: "FIELD OF VIEW",
            under: "360°C (HORIZONTAL), 30°C (VERTICAL)",
          },
          {
            above: "RANGE",
            under: "0m (MIN), 200m (MAX)",
          },
          {
            above: "SPEED",
            under: "600,000 pts/sec / 900,000 pts/sec (Long Range)",
          },
          {
            above: "DENSITY",
            under: "Based on capture speed",
          },
        ],
      },
      {
        section: "IMAGING",
        stats: [
          {
            above: "CAMERA",
            under:
              "40 MP 5-camera system, 2 fps x camera equal to 40 M pixels per second, 360° x 200°",
          },
          {
            above: "SPEED",
            under: "Continuous",
          },

          {
            above: "HDR",
            under: "N/A",
          },
          {
            above: "THERMAL",
            under: "Optional (external)",
          },
        ],
      },
      {
        section: "ENVIRONMENTAL",
        stats: [
          {
            above: "OPERATING TEMPERATURE",
            under: "0° (MIN), 40° (MAX)",
          },
          {
            above: "STORAGE TEMPERATURE",
            under: "° (MIN ), ° (MAX)",
          },

          {
            above: "IP RATING",
            under: "IP52 (Dust / Dripping water protected)",
          },
        ],
      },
      {
        section: "NAVIGATION SENSORS",
        stats: [
          {
            above: "VISUAL INERTIAL SYSTEM",
            under: "",
          },
          {
            above: "TILT",
            under: "",
          },

          {
            above: "ALTIMETER",
            under: "",
          },
          {
            above: "COMPASS",
            under: "",
          },
          {
            above: "GNSS",
            under: "Yes",
          },
        ],
      },
      {
        section: "ACCURACY",
        stats: [
          {
            above: "ANGULAR",
            under: "",
          },
          {
            above: "RANGE",
            under: "",
          },

          {
            above: "3D POINT ACCURACY",
            under: "@ 10m, @ 20m, @ 50m",
          },
        ],
      },
      {
        section: "DATA PROCESSING",
        stats: [
          {
            above: "DATA TRANSFER",
            under:
              "Ethernet and wireless connections available. Service support available through remote connection.",
          },
          {
            above: "DESKTOP SOFTWARE",
            under: "Pegasus Manager",
          },
        ],
      },
    ],
  },
  datasets: [
    "https://blk2021.com/wp-content/uploads/2021/10/BLK2FLY_SpecSheet-1.pdf",
    "https://blk2021.com/wp-content/uploads/2021/10/BLK2FLY_SpecSheet-1.pdf",
  ],

  ordering: [
    {
      title: "Ready to learn more?",
      paragraph:
        "Our EXPERTS@R-E-A-L.iT are ready to help you with onboarding this new technology in your business. Whether you need to train your employees with this new hardware, or have questions about what software solutions to use in the office to get the most out of your data, we are ready to assist you at every step of the process. Check out our coaching plans at https://r-e-a-l.it/services/coach. Still have questions? Click the button below to start a conversation with one of our experts.",
      buttonText: "TALK TO AN EXPERT@R-E-A-L.iT",
    },
    {
      title: "Ready to order?",
      paragraph:
        "Laser scanning is a revolutionary way to supercharge your workflow. At R-E-A-L.iT, we can help you increase the quality of your deliverables while also helping you work faster and smarter. Our hardware provides you with a lightning fast way to gather measurements, helping your teams get in and out of sites in record time, with more data than they could ever capture using traditional measurement techniques. Ready to buy? Click the button below to place an order.",
      buttonText: "ORDER NOW",
    },
  ],
  isUnboxing:
    "initial" /* if there is an unboxing section, put "initial", otherwise put "none" */,
};
export default PEGASUS_BACKPACK;
