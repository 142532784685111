import React from "react";
import "./Product.css";
import Specs from "../../components/specs/Specs";
import Features from "../../components/features/Features";
import Contact from "../contact/Contact";
import SubHeader from "../../components/subheader/SubHeader";

/* functions */

class Product extends React.Component {
  constructor(props) {
    super(props);
  }

  /* domifier */
  domify(str) {
    let dom = document.createElement("p");
    dom.innerHTML = str;
    return dom;
  }

  /*

  subheader - items (technology, usecases, specifications)

  overview 

    landing - productMame, video:ink (vimeo only!)
    advertise - title, videoLink, paragraph, sectionGradient
    sketchfab - modelLink
    videoSection[] - title, videoLink, pararaph, (optional) sectionGradient

  features[] (technology, usecases)

    landing - isVideo, linkToMedia, title
    section[] - imgLink, paragraph

  specifications (statistics, datasets)

    statistics - statslist[], statsImg
    datasets[] - title, buttonText, downloadLink

  */

  render() {
    const props = this.props.content;
    return (
      <div>
        <SubHeader array={props.headerItems} />
        <section id="landing">
          <h1 className="land-header ml3">{props.landing.productName}</h1>
          <hr className="land-divide" />
          <h2 className="land-subhead">{props.landing.subHeader}</h2>
          <iframe
            className="embed-responsive-item"
            src={props.landing.videoLink}
            allow="autoplay;"
            allowFullScreen=""
            id="vm-de75ef11-5059-44a9-9151-6a98f844d2b3"
            data-ready="true"
          ></iframe>
          <hr style={{ width: "75%", border: "1px solid #333" }} />
        </section>

        <section
          id={String(props.headerItems[0]).replaceAll(" ", "")}
          className="advertise"
          style={{
            display: props.advertise.active ? "initial" : "none",
            backgroundImage: `linear-gradient(#000000, ${props.sketchfab.gradientColor})`,
          }}
        >
          <div id="overview"></div>
          <h1 style={{ zIndex: "5", color: "white", padding: "25px" }}>
            {/* {props.advertise.title} (domify function isn't returning an error) */}
            {props.advertise.title}
            {/* The{" "}
            <span
              className="gradient-text"
              style={{
                backgroundImage: "linear-gradient(30deg, #24272A, #db0d0d)",
              }}
            >
              power
            </span>
            <br />
            of{" "}
            <span
              className="gradient-text"
              style={{
                backgroundImage: "linear-gradient(30deg, #24272A, #db0d0d)",
              }}
            >
              reality capture
            </span>{" "}
            technology
            <br />
            in the{" "}
            <span
              className="gradient-text"
              style={{
                backgroundImage: "linear-gradient(30deg, #24272A, #db0d0d)",
              }}
            >
              palm
            </span>{" "}
            of your hand. */}
          </h1>
          <iframe
            style={{ zIndex: "5" }}
            src={props.advertise.videoLink}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <p style={{ textAlign: "center", color: "#888", zIndex: "5" }}>
            {props.advertise.paragraph}
          </p>
        </section>

        <section id="sketchfab">
          <iframe
            loading="lazy"
            width="100%"
            height="100%"
            src={props.sketchfab.modelLink}
          ></iframe>
          <hr />
        </section>

        <div
          style={{
            margin: "none",
            padding: "none",
            display: props.videosActive ? "initial" : "none",
          }}
        >
          <section
            id="presenting"
            style={{
              backgroundImage: `linear-gradient(${props.sketchfab.gradientColor}, #000000)`,
              display: props.videoSections[0].active ? "initial" : "none",
            }}
          >
            <div
              className="vidwrap"
              style={{
                backgroundImage: `linear-gradient(${props.sketchfab.gradientColor}, #000000)`,
              }}
            >
              <div className="vidleft">
                <h1
                  className="gradient-text"
                  style={{
                    backgroundImage:
                      "linear-gradient(120deg, #24272A, #db0d0d)",
                  }}
                >
                  {props.videoSections[0].title}
                </h1>
                <hr
                  style={{
                    width: "100px",
                    float: "left",
                    border: "1px solid #333",
                  }}
                />{" "}
                <br />
                <br />
                <p>{props.videoSections[0].paragraph}</p>
              </div>
              <div className="midline"></div>
              <div className="vidright">
                <iframe
                  className="yt-vid"
                  src={props.videoSections[0].video}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </section>

          <section
            id="using"
            style={{
              display: props.videoSections[1].active ? "initial" : "none",
            }}
          >
            <canvas className="hexagon-trail"></canvas>
            <div className="vidwrap" style={{ zIndex: "5" }}>
              <div className="vidleft vid-order-hook">
                <iframe
                  className="yt-vid"
                  src={props.videoSections[1].video}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
              <div className="midline"></div>
              <div className="vidright text-order-hook">
                <h1
                  className="gradient-text"
                  style={{
                    backgroundImage:
                      "linear-gradient(120deg, #24272A, #db0d0d)",
                  }}
                >
                  {props.videoSections[1].title}
                </h1>
                <hr
                  style={{
                    width: "100px",
                    float: "left",
                    border: "1px solid #333",
                  }}
                />
                <br />
                <br />
                <p>{props.videoSections[1].paragraph}</p>
              </div>
            </div>
          </section>

          <section
            id="demonstrating"
            style={{
              display: props.videoSections[2].active ? "initial" : "none",
            }}
          >
            <div className="vidwrap">
              <div className="vidleft">
                <h1
                  className="gradient-text"
                  style={{
                    backgroundImage:
                      "linear-gradient(120deg, #24272A, #db0d0d)",
                  }}
                >
                  {props.videoSections[2].title}
                </h1>
                <hr
                  style={{
                    width: "100px",
                    float: "left",
                    border: "1px solid #333",
                  }}
                />
                <br />
                <br />
                <p>{props.videoSections[2].paragraph}</p>
              </div>
              <div className="midline"></div>
              <div className="vidright">
                <iframe
                  className="yt-vid"
                  src={props.videoSections[2].video}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </section>

          <section
            id="unboxing"
            style={{
              display: props.videoSections[3].active ? "initial" : "none",
            }}
          >
            <div className="vidwrap" style={{ zIndex: "5" }}>
              <div className="vidleft vid-order-hook">
                <iframe
                  className="yt-vid"
                  src={props.videoSections[3].video}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
              <div className="midline"></div>
              <div className="vidright text-order-hook">
                <h1
                  className="gradient-text"
                  style={{
                    backgroundImage:
                      "linear-gradient(120deg, #24272A, #db0d0d)",
                  }}
                >
                  {props.videoSections[3].title}
                </h1>
                <hr
                  style={{
                    width: "100px",
                    float: "left",
                    border: "1px solid #333",
                  }}
                />
                <br />
                <br />
                <p>{props.videoSections[3].paragraph}</p>
              </div>
            </div>
          </section>
        </div>

        {/*
        
        Technology Section 
        
        */}

        <section
          className="technology section"
          id={String(props.headerItems[1]).replaceAll(" ", "")}
          style={{ borderTop: "2px solid #701109" }}
        >
          <h1
            style={{
              position: "absolute",
              zIndex: "5",
              width: "100vw",
              textAlign: "center",
              paddingTop: "30vh",
              fontSize: "5em",
            }}
          >
            <hr
              style={{
                width: "50px",
                border: "1px solid #db0d0d",
                zIndex: "5",
              }}
            />
            Technology
            <hr
              style={{
                width: "50px",
                border: "1px solid #db0d0d",
                zIndex: "5",
              }}
            />
          </h1>
          <iframe
            id="background-video"
            className="embed-responsive-item"
            src={props.features[0].mediaLink}
            allow="autoplay;"
            allowFullScreen=""
            id="vm-de75ef11-5059-44a9-9151-6a98f844d2b3"
            data-ready="true"
          ></iframe>
          {/* <video id="background-video" autoPlay loop muted>
            <source src={props.features[0].mediaLink} type="video/mp4" />
          </video> */}
        </section>

        <div class="features" id="technology-list">
          <Features array={props.features[0].sections} />
        </div>

        {/*
        
        Use Cases Section 
        
        */}

        <section
          className="technology usecases section"
          id={String(props.headerItems[2]).replaceAll(" ", "")}
          style={{ marginTop: 0, borderTop: "1px solid #701109" }}
        >
          <h1
            style={{
              position: "absolute",
              zIndex: "5",
              width: "100vw",
              textAlign: "center",
              fontSize: "5em",
              paddingTop: "30vh",
            }}
          >
            <hr
              style={{
                width: "50px",
                border: "1px solid #db0d0d",
                zIndex: "5",
              }}
            />
            Use Cases
            <hr
              style={{
                width: "50px",
                border: "1px solid #db0d0d",
                zIndex: "5",
              }}
            />
          </h1>
          <iframe
            id="background-video"
            className="embed-responsive-item"
            src={props.features[1].mediaLink}
            allow="autoplay;"
            allowFullScreen=""
            id="vm-de75ef11-5059-44a9-9151-6a98f844d2b3"
            data-ready="true"
          ></iframe>
          {/* <video
            id="background-video"
            autoPlay
            loop
            muted
            style={{ width: "100vw" }}
          >
            <source src={props.features[1].mediaLink} type="video/mp4" />
          </video> */}
        </section>

        <div class="features" id="usecases-list">
          <Features array={props.features[1].sections} />
        </div>

        <section
          className="specifications section"
          id={String(props.headerItems[3]).replaceAll(" ", "")}
          style={{ marginTop: 0, borderTop: "1px solid #701109" }}
        >
          <h1
            style={{
              position: "absolute",
              zIndex: "5",
              width: "100vw",
              textAlign: "center",
              paddingTop: "10vh",
              fontSize: "5em",
            }}
          >
            <hr
              style={{
                width: "50px",
                border: "1px solid #db0d0d",
                zIndex: "5",
              }}
            />
            {props.statistics.specsTitle}
            <hr
              style={{
                width: "50px",
                border: "1px solid #db0d0d",
                zIndex: "5",
              }}
            />
          </h1>
          <video
            id="background-video"
            autoPlay
            loop
            muted
            style={{ width: "100vw" }}
          >
            <source src={props.statistics.specsVideo} type="video/mp4" />
          </video>
        </section>
        <section id="statistics">
          <div className="specs-above">
            <span>Specifications</span>
            <h3>{props.statistics.smallTitle}</h3>
            <hr />
            <br />
          </div>
          <div
            className="specs-container"
            style={
              {
                // width: "calc(100vw - 100px)",
                // maxWidth: "calc(100vw - 100px)",
                // marginLeft: "50px",
                // display: "grid",
                // gap: "20px",
                // gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
                // gridTemplateRows: "1fr",
              }
            }
          >
            <Specs array={props.statistics.statsItems} />
          </div>
        </section>
        <div
          // className="specs-column"
          id="stats-column-image"
          style={{ display: props.statistics.imageActive ? "block" : "none" }}
          // style={{ width: "75vw", marginLeft: "calc(50vw - 37.5vw)" }}
        >
          <img
            src={props.statistics.statsImage}
            alt="BLK2GO measurements and dimensions"
            style={{ width: "100%" }}
          />
        </div>
        <hr className="stats-under" />
        {/* <section id="datasets" style={{ marginTop: "100px" }}>
          <div>
            <h1>Download the spec sheet.</h1>
            <div>
              <button
                className="button button--hyperion"
                onClick={() => {
                  window.open("mailto:experts@r-e-a-l.it", "_blank");
                }}
                style={{ marginRight: "15px" }}
              >
                <span>
                  <span>Contact</span>
                </span>
              </button>
              <button
                className="button button--hyperion"
                onClick={() => {
                  window.open(props.datasets[0], "_blank");
                }}
              >
                <span>
                  <span>Download</span>
                </span>
              </button>
            </div>
          </div>
          <div>
            <h1>Download the datasets.</h1>
            <div>
              <button
                className="button button--hyperion"
                onClick={() => {
                  window.open("mailto:experts@r-e-a-l.it", "_blank");
                }}
                style={{ marginRight: "15px" }}
              >
                <span>
                  <span>Contact</span>
                </span>
              </button>
              <button
                className="button button--hyperion"
                onClick={() => {
                  window.open(props.datasets[1], "_blank");
                }}
              >
                <span>
                  <span>Download</span>
                </span>
              </button>
            </div>
          </div>
          <hr className="stats-under" />
        </section> */}

        <div id={String(props.headerItems[4]).replaceAll(" ", "")}></div>
        <Contact displayGradient={false} />

        <section id="ordering">
          <div className="order-options">
            <div className="ordering-column" id="order-border">
              <h1>{props.ordering[0].title}</h1>
              <p>{props.ordering[0].paragraph}</p>
              <button
                className="button button--hyperion"
                onClick={() => {
                  window.location.href =
                    "mailto:experts@r-e-a-l.it?subject=More%20info%20about%20BLK2GO&body=Hello,%20I'm%20looking%20for%20more%20information%20about%20the%20BLK2GO.";
                }}
              >
                <span>
                  <span>{props.ordering[0].buttonText}</span>
                </span>
              </button>
            </div>
            <div className="ordering-column">
              <h1>{props.ordering[1].title}</h1>
              <p>{props.ordering[1].paragraph}</p>
              <button
                className="button button--hyperion"
                onClick={() => {
                  window.location.href =
                    "mailto:experts@r-e-a-l.it?subject=Quote%20for%20BLK2GO&body=Hello,%20please%20send%20me%20a%20quote%20for%20the%20BLK2GO.";
                }}
              >
                <span>
                  <span>{props.ordering[1].buttonText}</span>
                </span>
              </button>
            </div>
          </div>
          <div className="ordering-below">
            <img src="https://r-e-a-l.it/capture/images/etcetera/capture-reality.gif" />
          </div>
        </section>
      </div>
    );
  }
}

export default Product;
