const Cyclone_3DR = {
  headerItems: ["overview", "features", "products", "contact"],
  landing: {
    productName: "Cyclone 3DR",
    imageLink:
      "https://r-e-a-l.it/Images-Updated/Swiper-Software-Background.png",
    subHeader: "EFFICIENT REALITY SOFTWARE",
  },
  advertise: {
    active: false,
    title: "",
    videoLink: "",
    paragraph: "",
  },
  featuresActive: true,
  featuresSections: [
    {
      active: true,
      id: "ALL IN ONE SOLUTION",
      title: "ALL IN ONE SOLUTION",
      image: "",
      paragraph:
        "Get professional results in a fraction of the time. Leica Cyclone 3DR is a seamless extension of the Cyclone product family which works hand in hand to take users from field data collection with Cyclone FIELD 360 and Cyclone FIELDWORX through registration in Cyclone REGISTER 360 and deliverable creation in Cyclone 3DR.",
    },
    {
      active: true,
      id: "CENTRALISED POINT CLOUD MANAGEMENT",
      title: "CENTRALISED POINT CLOUD MANAGEMENT",
      image: "",
      paragraph:
        "Cyclone 3DR fuses JetStream technology for centralised, full-scale point cloud management with automated point cloud analysis and modelling into one simple workflow-based software with tailored tools for the surveying, construction and inspection fields.",
    },
    {
      active: true,
      id: "SIMPLE AND INTUITIVE",
      title: "SIMPLE AND INTUITIVE",
      image: "",
      paragraph:
        "Based upon the simple design of the next-generation Cyclone family, Cyclone 3DR allows both new and experienced users to move from import through analysis and publishing intuitively and simply. By automating common tasks, customers can reduce unexpected project delays and quickly deliver actionable information to clients.",
    },
    {
      active: true,
      id: "",
      title: "",
      video: "",
      paragraph: "",
    },
  ],
  compatibleProducts: [
    {
      image: "",
      name: "",
      blurb: "",
      link: "",
    },
    {
      image: "",
      name: "",
      blurb: "",
      link: "",
    },
  ],
  ordering: [
    {
      title: "Ready to learn more?",
      paragraph:
        "Our EXPERTS@R-E-A-L.iT are ready to help you with onboarding this new technology in your business. Whether you need to train your employees with this new hardware, or have questions about what software solutions to use in the office to get the most out of your data, we are ready to assist you at every step of the process. Check out our coaching plans here. Still have questions? Click HERE to start a conversation with one of our experts.",
      buttonText: "TALK TO AN EXPERT@R-E-A-L.iT",
    },
    {
      title: "Ready to order?",
      paragraph:
        "Laser scanning is a revolutionary way to supercharge your workflow. At R-E-A-L.iT, we can help you increase the quality of your deliverables while also helping you work faster and smarter. The BLK2GO is a simple, sleek, and lightning fast way to gather measurements, helping your teams get in and out of sites in record time, with more data than they could ever capture using traditional measurement techniques. Ready to buy? Click HERE to place an order.",
      buttonText: "ORDER NOW",
    },
  ],
};
export default Cyclone_3DR;
