import React from "react";
import "./Software.css";
import Contact from "../contact/Contact";
import SubHeader from "../../components/subheader/SubHeader";
import Products from "../../components/products/Products";

/* functions */

class Software extends React.Component {
  constructor(props) {
    super(props);
  }

  /* domifier */
  domify(str) {
    let dom = document.createElement("p");
    dom.innerHTML = str;
    return dom;
  }

  render() {
    const props = this.props.content;
    return (
      <div>
        <SubHeader array={props.headerItems} />
        <section id="landing">
          <h1 className="land-header-software ml3">
            {props.landing.productName}
          </h1>
          <hr className="land-divide" />
          <h2 className="land-subhead">{props.landing.subHeader}</h2>
          <img src={props.landing.imageLink}></img>
          <hr style={{ width: "75%", border: "1px solid #333" }} />
        </section>

        <section
          id={String(props.headerItems[0]).replaceAll(" ", "")}
          className="advertise"
          style={{
            display: props.advertise.active ? "initial" : "none",
            // backgroundImage: `linear-gradient(#000000, ${props.sketchfab.gradientColor})`,
          }}
        >
          <div id="overview"></div>
          <h1 style={{ zIndex: "5", color: "white", padding: "25px" }}>
            {/* {props.advertise.title} (domify function isn't returning an error) */}
            {props.advertise.title}
            {/* The{" "}
            <span
              className="gradient-text"
              style={{
                backgroundImage: "linear-gradient(30deg, #24272A, #db0d0d)",
              }}
            >
              power
            </span>
            <br />
            of{" "}
            <span
              className="gradient-text"
              style={{
                backgroundImage: "linear-gradient(30deg, #24272A, #db0d0d)",
              }}
            >
              reality capture
            </span>{" "}
            technology
            <br />
            in the{" "}
            <span
              className="gradient-text"
              style={{
                backgroundImage: "linear-gradient(30deg, #24272A, #db0d0d)",
              }}
            >
              palm
            </span>{" "}
            of your hand. */}
          </h1>
          <iframe
            style={{ zIndex: "5" }}
            src={props.advertise.videoLink}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <p style={{ textAlign: "center", color: "#888", zIndex: "5" }}>
            {props.advertise.paragraph}
          </p>
        </section>

        <div
          id="features"
          style={{
            margin: "none",
            padding: "none",
            display: props.featuresActive ? "initial" : "none",
          }}
        >
          <section
            id={props.featuresSections[0].id}
            style={{
              // backgroundImage: `linear-gradient(${props.sketchfab.gradientColor}, #000000)`,
              display: props.featuresSections[0].active ? "initial" : "none",
            }}
          >
            <div
              className="vidwrap"
              // style={{
              //   backgroundImage: `linear-gradient(${props.sketchfab.gradientColor}, #000000)`,
              // }}
            >
              <div className="vidleft">
                <h1
                  className="gradient-text"
                  style={{
                    backgroundImage:
                      "linear-gradient(120deg, #24272A, #db0d0d)",
                  }}
                >
                  {props.featuresSections[0].title}
                </h1>
                <hr
                  style={{
                    width: "100px",
                    float: "left",
                    border: "1px solid #333",
                  }}
                />{" "}
                <br />
                <br />
                <p>{props.featuresSections[0].paragraph}</p>
              </div>
              <div className="midline"></div>
              <div className="vidright">
                <img
                  className="yt-vid"
                  src={props.featuresSections[0].image}
                ></img>
              </div>
            </div>
          </section>

          <section
            id={props.featuresSections[1].id}
            style={{
              display: props.featuresSections[1].active ? "initial" : "none",
            }}
          >
            <canvas className="hexagon-trail"></canvas>
            <div className="vidwrap" style={{ zIndex: "5" }}>
              <div className="vidleft vid-order-hook">
                <img
                  className="yt-vid"
                  src={props.featuresSections[1].image}
                ></img>
              </div>
              <div className="midline"></div>
              <div className="vidright text-order-hook">
                <h1
                  className="gradient-text"
                  style={{
                    backgroundImage:
                      "linear-gradient(120deg, #24272A, #db0d0d)",
                  }}
                >
                  {props.featuresSections[1].title}
                </h1>
                <hr
                  style={{
                    width: "100px",
                    float: "left",
                    border: "1px solid #333",
                  }}
                />
                <br />
                <br />
                <p>{props.featuresSections[1].paragraph}</p>
              </div>
            </div>
          </section>

          <section
            id={props.featuresSections[2].id}
            style={{
              display: props.featuresSections[2].active ? "initial" : "none",
            }}
          >
            <div className="vidwrap">
              <div className="vidleft">
                <h1
                  className="gradient-text"
                  style={{
                    backgroundImage:
                      "linear-gradient(120deg, #24272A, #db0d0d)",
                  }}
                >
                  {props.featuresSections[2].title}
                </h1>
                <hr
                  style={{
                    width: "100px",
                    float: "left",
                    border: "1px solid #333",
                  }}
                />
                <br />
                <br />
                <p>{props.featuresSections[2].paragraph}</p>
              </div>
              <div className="midline"></div>
              <div className="vidright">
                <img
                  className="yt-vid"
                  src={props.featuresSections[2].image}
                ></img>
              </div>
            </div>
          </section>

          <section
            id={props.featuresSections[3].id}
            style={{
              display: props.featuresSections[3].active ? "initial" : "none",
            }}
          >
            <div className="vidwrap" style={{ zIndex: "5" }}>
              <div className="vidleft vid-order-hook">
                <img
                  className="yt-vid"
                  src={props.featuresSections[3].image}
                ></img>
              </div>
              <div className="midline"></div>
              <div className="vidright text-order-hook">
                <h1
                  className="gradient-text"
                  style={{
                    backgroundImage:
                      "linear-gradient(120deg, #24272A, #db0d0d)",
                  }}
                >
                  {props.featuresSections[3].title}
                </h1>
                <hr
                  style={{
                    width: "100px",
                    float: "left",
                    border: "1px solid #333",
                  }}
                />
                <br />
                <br />
                <p>{props.featuresSections[3].paragraph}</p>
              </div>
            </div>
          </section>
        </div>

        <div id="products" class="related-products">
          <Products array={props.compatibleProducts} />
        </div>

        <div id={String(props.headerItems[4]).replaceAll(" ", "")}></div>
        <Contact displayGradient={false} />

        <section id="ordering">
          <div className="order-options">
            <div className="ordering-column" id="order-border">
              <h1>{props.ordering[0].title}</h1>
              <p>{props.ordering[0].paragraph}</p>
              <button
                className="button button--hyperion"
                onClick={() => {
                  window.location.href =
                    "mailto:experts@r-e-a-l.it?subject=More%20info%20about%20BLK2GO&body=Hello,%20I'm%20looking%20for%20more%20information%20about%20the%20BLK2GO.";
                }}
              >
                <span>
                  <span>{props.ordering[0].buttonText}</span>
                </span>
              </button>
            </div>
            <div className="ordering-column">
              <h1>{props.ordering[1].title}</h1>
              <p>{props.ordering[1].paragraph}</p>
              <button
                className="button button--hyperion"
                onClick={() => {
                  window.location.href =
                    "mailto:experts@r-e-a-l.it?subject=Quote%20for%20BLK2GO&body=Hello,%20please%20send%20me%20a%20quote%20for%20the%20BLK2GO.";
                }}
              >
                <span>
                  <span>{props.ordering[1].buttonText}</span>
                </span>
              </button>
            </div>
          </div>
          <div className="ordering-below">
            <img src="https://r-e-a-l.it/capture/images/etcetera/capture-reality.gif" />
          </div>
        </section>
      </div>
    );
  }
}

export default Software;
