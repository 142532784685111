const Cyclone_Register360 = {
  headerItems: ["overview", "features", "products", "contact"],
  landing: {
    productName: "Cyclone Register 360",
    imageLink:
      "https://r-e-a-l.it/Images-Updated/Swiper-Software-Background.png",
    subHeader: "EFFICIENT REALITY SOFTWARE",
  },
  advertise: {
    active: false,
    title: "Here's an advertisement video of the Cyclone Register 360.",
    videoLink: "https://www.youtube.com/embed/1tpDfZ_6Svo",
    paragraph:
      "The BLK2GO from Leica Geosystems is the fastest and easiest way to scan any large space or structure. While you simply walk through your surroundings, the BLK2GO collects millions of data points, creating a digital twin of your environment for creating accurate floorplans, BIM models, and 3D visualizations. The BLK2GO makes reality capture and the power of point clouds accessible to everyone.",
  },
  featuresActive: true,
  featuresSections: [
    {
      active: true,
      id: "speed",
      title: "SPEED",
      image: "https://r-e-a-l.it/Images-Updated/Swiper-Software-Background.png",
      paragraph:
        "Drag, Drop, Done. From project creation to final reporting, project completion is significantly faster thanks to multi-threaded batch routines, one-step import and processing, guided workflows, built-in QA/QC tools and automated reporting to save considerable time and guesswork while delivering higher productivity. Users of the Leica RTC360 3D laser scanner can also leverage the system’s unique on-bard pre-registration feature to further accelerate the process.",
    },
    {
      active: true,
      id: "scale",
      title: "SCALE",
      image: "https://r-e-a-l.it/Images-Updated/Swiper-Software-Background.png",
      paragraph:
        "Cyclone REGISTER 360 handles even the most challenging of projects with ease and simplifies complex workflows. It empowers users of any skill-level to work smarter, deliver results more accurately, visualise in more detail and collaborate more effectively - placing the user at the centre of their projects. Whether deployed as a stand-alone solution or as part of an end-to-end integrated laser scanning solution, Cyclone REGISTER 360 is your fastest and easiest option to get the job done right, the first time.",
    },
    {
      active: true,
      id: "simplicity",
      title: "SIMPLICITY",
      image: "https://r-e-a-l.it/Images-Updated/Swiper-Software-Background.png",
      paragraph:
        "Deliver professional-level project deliverables with ease. Cyclone REGISTER 360 provides guided registration workflows to assist new users and speed the process for experienced users. Intuitive QA tools and reporting take the guesswork out of determining the quality of your registration, and put all the information you need at your fingertips. Batch export your final products to numerous industry-standard formats or the all-new Leica Geosystems Universal project file (LGS). Even push your data direct to the cloud for collaboration with the click of a button.",
    },
    {
      active: true,
      id: "fieldtofinish",
      title: "FIELD TO FINISH",
      image: "https://r-e-a-l.it/Images-Updated/Swiper-Software-Background.png",
      paragraph:
        "Cyclone REGISTER 360 is part of the integrated RTC360 Field-to-Finished solution. Taking advantage of the RTC360’s Visual Inertial System (VIS), Cyclone REGISTER 360 will automatically place project data on real-world coordinates and complete a registration with little to no manual assistance from the user. Projects can be published into the all-new Leica Geosystems Universal project file (LGS) for use downstream in every HDS Software product. One file, endless possibilities.",
    },
  ],
  compatibleProducts: [
    {
      image:
        "https://shop.leica-geosystems.com/sites/default/files/2021-01/BLK2GO_back-fade.jpg" /* should be a square image */,
      name: "BLK2GO",
      blurb:
        "The BLK2GO from Leica Geosystems is the fastest and easiest way to scan any large space or structure. While you simply walk through your surroundings, the BLK2GO collects millions of data points, creating a digital twin of your environment for creating accurate floorplans, BIM models, and 3D visualizations. The BLK2GO makes reality capture and the power of point clouds accessible to everyone.",
      link: "https://r-e-a-l.it/equipment/imaging/blk2go",
    },
    {
      image:
        "https://cdn11.bigcommerce.com/s-ew2v2d3jn1/images/stencil/1280x1280/products/293/943/leica-rtc360-lt-3d-laser-scanner-b__39785.1637074908.jpg?c=1" /* should be a square image */,
      name: "RTC360",
      blurb:
        "The RTC360 from Leica Geosystems is the fastest and easiest way to scan any large space or structure. While you simply walk through your surroundings, the RTC360 collects millions of data points, creating a digital twin of your environment for creating accurate floorplans, BIM models, and 3D visualizations. The RTC360 makes reality capture and the power of point clouds accessible to everyone.",
      link: "https://r-e-a-l.it/equipment/terrestrial/rtc360",
    },
  ],
  ordering: [
    {
      title: "Ready to learn more?",
      paragraph:
        "Our EXPERTS@R-E-A-L.iT are ready to help you with onboarding this new technology in your business. Whether you need to train your employees with this new hardware, or have questions about what software solutions to use in the office to get the most out of your data, we are ready to assist you at every step of the process. Check out our coaching plans here. Still have questions? Click HERE to start a conversation with one of our experts.",
      buttonText: "TALK TO AN EXPERT@R-E-A-L.iT",
    },
    {
      title: "Ready to order?",
      paragraph:
        "Laser scanning is a revolutionary way to supercharge your workflow. At R-E-A-L.iT, we can help you increase the quality of your deliverables while also helping you work faster and smarter. The BLK2GO is a simple, sleek, and lightning fast way to gather measurements, helping your teams get in and out of sites in record time, with more data than they could ever capture using traditional measurement techniques. Ready to buy? Click HERE to place an order.",
      buttonText: "ORDER NOW",
    },
  ],
};
export default Cyclone_Register360;
