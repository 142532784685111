const PEGASUS_TWO_ULTIMATE = {
  headerItems: [
    "overview",
    "technology",
    "use cases",
    "specifications",
    "request a demo",
  ],
  landing: {
    productName: "PEGASUS:TWO ULTIMATE",
    videoLink: "Insert link to PEGASUS:TWO ULTIMATE OVERVIEW video here.",
    subHeader: "EFFICIENT REALITY CAPTURE",
  },
  advertise: {
    active: true,
    title: "The best in mobile mapping solutions",
    videoLink: "https://www.youtube.com/embed/4bIjKMX85IY",
    paragraph:
      "A smart city relies on the accurate digitisation of infrastructure. The Pegasus:Two Ultimate enables you to deliver the highest quality and quantity of data quickly, safely and easily. ",
  },
  sketchfab: {
    gradientColor: "#161616",
    modelLink: "Insert link to PEGASUS:TWO ULTIMATE 3D MODEL 3D model here.",
  },
  videosActive: true,
  videoSections: [
    {
      active: true,
      id: "PRESENTING",
      title: "Gather enormous amounts of data with record speed",
      video: "https://www.youtube.com/embed/RS26vqcRap8",
      paragraph:
        "Add hours to your workday. With advanced sensors that allow data capture in a variety of lighting conditions and at varying speeds, you can gather more data each day, with greater reliability than other mobile mapping systems on the market. ",
    },
    {
      active: true,
      id: "USING",
      title: "A flexible solution, with impeccably accurate results",
      video: "https://www.youtube.com/embed/llmEmsEBY1E",
      paragraph:
        "Dual-light sensors, 12 MP side-cameraas, 24 MP 360 degree imagery, and a telescopic pole make the Pegasus: Two Ultimate the top of the line mobile mapping solution. A removable solid-state hard disk with integrated USB 3.0 connection allows you to take your data with you easily and quickly. Additional ports allow the connection of thermal imaging equipment, sonar, ground-penetrating radar sensors, side pavement cameras, and more. ",
    },
    {
      active: true,
      id: "DEMONSTRATING",
      title: "A complete and reliable system for moving cities into the future",
      video: "https://www.youtube.com/embed/5iu33dPzIC4",
      paragraph:
        "Seamless 360 degree imagery calibrated to your point cloud can be capture from car, train, or boat. No matter your needs, the Pegasus Two:Ultimate can provide high quality digital reality capture solutions for all your smart city applications. ",
    },
    {
      active: true,
      id: "UNBOXING",
      title: "Serious solutions for significant needs",
      video: "https://www.youtube.com/embed/1ZSHjKXJ_z0",
      paragraph:
        "The Pegasus Two:Ultimate from Leica Geosystems is a comprehensive smart city digitisation tool that will bring you to the forefront of the industry. With a seamless integration between this serious hardware and our complete selection of software solutions, a futuristic smart city can be a present reality. ",
    },
  ],
  features: [
    {
      title: "TECHNOLOGY",
      topVideo: true,
      mediaLink: "",
      sections: [
        {
          title: "Back-to-Back Cameras",
          image: "",
          paragraph:
            "Two integrated back-to-back cameras create a 24 MP, 360 degree image calibrated to the LiDAR profiler. ",
        },
        {
          title: "Supreme photogrammetry and image quality",
          image: "",
          paragraph:
            "Large sensor to pixel ratio and dual-light sensor enable to you to work within a greater range of lighting conditions, extending your working day. ",
        },
        {
          title: "Adjustable pavement camera",
          image: "",
          paragraph:
            "Wtih 12 mm lenses, the adjustable pavements cameras enable targeted imaging capture along a road or in a tunnel. ",
        },
        {
          title: "Sensor expansion ports",
          image: "",
          paragraph:
            "Customize how you digitize your city with ports to add other types of sensors, such as external cameras, an additional GPS, or wheel sensors, etc. ",
        },
      ],
    },
    {
      title: "USE CASES",
      topVideo: true,
      mediaLink: "",
      sections: [
        {
          title: "",
          image: "",
          paragraph: "",
        },
        {
          title: "",
          image: "",
          paragraph: "",
        },

        {
          title: "",
          image: "",
          paragraph: "",
        },
        {
          title: "",
          image: "",
          paragraph: "",
        },
        {
          title: "",
          image: "",
          paragraph: "",
        },
      ],
    },
  ],
  statistics: {
    specsTitle: "Specifications",
    specsVideo:
      "https://player.vimeo.com/video/500181508?background=1&autoplay=1",
    smallTitle: "Get the specs.",
    statsImage:
      "https://shop.leica-geosystems.com/sites/default/files/inline-images/BLK2GO-measurement-drawing_v2_0.png",
    statsItems: [
      {
        section: "DESIGN & PHYSICAL",
        stats: [
          {
            above: "HOUSING",
            under: "",
          },
          {
            above: "",
            under: "Width: 650 mm (25.59 in.), Height: 680 mm (26.77 in.)",
          },
          {
            above: "WEIGHT",
            under: "51 kgg (85.8 kgg including battery)",
          },
          {
            above: "MOUNTING",
            under: "Rotational platform to mount on almost any vehicle",
          },
        ],
      },
      {
        section: "OPERATION",
        stats: [
          {
            above: "ON SCANNER",
            under: "N/A",
          },
          {
            above: "CONTROL",
            under: "Multi-core industrial PC, low power consumption",
          },
          {
            above: "WIRELESS",
            under:
              "Ethernet and wireless connections available. Service support available through remote connection.",
          },
          {
            above: "DATA STORAGE",
            under:
              "Removable 1 TB HDD with USB3.0 interface, sync port for PPS/NMEA/DMI outputs.",
          },
        ],
      },
      {
        section: "POWER",
        stats: [
          {
            above: "INTERNAL",
            under: "N/A",
          },
          {
            above: "EXTERNAL",
            under: "9 hrs (profiler version); 13 hrs (scanner version)",
          },
        ],
      },
      {
        section: "SCANNING",
        stats: [
          {
            above: "DISTANCE MEASUREMENT",
            under: "High-speed phase-based laser scanner, 1500nm wavelength",
          },
          {
            above: "LASER CLASS",
            under: "1 (according to EN60825-1 / ANSI Z136.1)",
          },
          {
            above: "FIELD OF VIEW",
            under: "360°C (HORIZONTAL), 167°C (VERTICAL)",
          },
          {
            above: "RANGE",
            under: "0.3m (MIN), 119m (MAX)",
          },
          {
            above: "SPEED",
            under: "Up to 1,000,000 pts/sec",
          },
          {
            above: "DENSITY",
            under:
              "Based on capture speed and rotation speed of laser profiler",
          },
        ],
      },
      {
        section: "IMAGING",
        stats: [
          {
            above: "CAMERA",
            under:
              "2 dual fish-eye cameras, 24 MP panoramic camera system (2 x 12 MP)",
          },
          {
            above: "SPEED",
            under: "Continuous",
          },

          {
            above: "HDR",
            under: "N/A",
          },
          {
            above: "THERMAL",
            under: "Optional (external)",
          },
        ],
      },
      {
        section: "ENVIRONMENTAL",
        stats: [
          {
            above: "OPERATING TEMPERATURE",
            under: "-10° (MIN), 45° (MAX)",
          },
          {
            above: "STORAGE TEMPERATURE",
            under: "-20° (MIN ), 50° (MAX)",
          },

          {
            above: "IP RATING",
            under: "IP54",
          },
        ],
      },
      {
        section: "NAVIGATION SENSORS",
        stats: [
          {
            above: "VISUAL INERTIAL SYSTEM",
            under: "",
          },
          {
            above: "TILT",
            under: "",
          },

          {
            above: "ALTIMETER",
            under: "",
          },
          {
            above: "COMPASS",
            under: "",
          },
          {
            above: "GNSS",
            under: "Yes",
          },
        ],
      },
      {
        section: "ACCURACY",
        stats: [
          {
            above: "ANGULAR",
            under: "",
          },
          {
            above: "RANGE",
            under: "",
          },

          {
            above: "3D POINT ACCURACY",
            under: "@ 10m, @ 20m, @ 50m",
          },
        ],
      },
      {
        section: "DATA PROCESSING",
        stats: [
          {
            above: "DATA TRANSFER",
            under:
              "USB, ethernet and wireless connections available through the battery system. Service support available through remote interface.",
          },
          {
            above: "DESKTOP SOFTWARE",
            under: "Pegasus Manager",
          },
        ],
      },
    ],
  },
  datasets: [
    "https://blk2021.com/wp-content/uploads/2021/10/BLK2FLY_SpecSheet-1.pdf",
    "https://blk2021.com/wp-content/uploads/2021/10/BLK2FLY_SpecSheet-1.pdf",
  ],

  ordering: [
    {
      title: "Ready to learn more?",
      paragraph:
        "Our EXPERTS@R-E-A-L.iT are ready to help you with onboarding this new technology in your business. Whether you need to train your employees with this new hardware, or have questions about what software solutions to use in the office to get the most out of your data, we are ready to assist you at every step of the process. Check out our coaching plans at https://r-e-a-l.it/services/coach. Still have questions? Click the button below to start a conversation with one of our experts.",
      buttonText: "TALK TO AN EXPERT@R-E-A-L.iT",
    },
    {
      title: "Ready to order?",
      paragraph:
        "Laser scanning is a revolutionary way to supercharge your workflow. At R-E-A-L.iT, we can help you increase the quality of your deliverables while also helping you work faster and smarter. Our hardware provides you with a lightning fast way to gather measurements, helping your teams get in and out of sites in record time, with more data than they could ever capture using traditional measurement techniques. Ready to buy? Click the button below to place an order.",
      buttonText: "ORDER NOW",
    },
  ],
};
export default PEGASUS_TWO_ULTIMATE;
