import React from "react";

class Products extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      count: 1,
      list: props.array,
    };
  }

  render() {
    const products = this.state.list.map((i) => {
      return (
        <div
          className="products-item"
          style={{ gridColumn: "span 1", listStyle: "none" }}
        >
          <img src={i.image}></img>
          <h1>{i.name}</h1>
          <hr class="pr-item-hr" />
          <p>{i.blurb}</p>
          <button
            className="button button--hyperion"
            onClick={() => {
              window.open(i.link, "_blank");
            }}
            style={{ marginRight: "15px" }}
          >
            <span>
              <span>view product</span>
            </span>
          </button>
        </div>
      );
    });
    return products;
  }
}

export default Products;
