import React, { useState } from "react";
import "./Header.css";

const toggleMenu = (menu) => {
  if (menu.style.display == "initial") {
    menu.style.display = "none";
  } else {
    menu.style.display = "initial";
  }
};

// window.onload = () => {
//   var burger = document.querySelector(".nav-burger");
//   var menu = document.querySelector(".nav-mobile");
//   var active = false;

//   function toggleMenu() {
//     if (active) {
//       menu.style.display = "none";
//       active = false;
//     } else {
//       menu.style.display = "initial";
//       active = true;
//     }
//   }

//   burger.addEventListener("click", toggleMenu);
// };

const Header = () => {
  // const [dropActive, setActive] = useState("false");
  // function showDrop() {
  // document.getElementById("solutions-drop").classList.toggle("show");
  //   setActive(dropActive);
  // }
  // window.addEventListener("click", (event) => {
  //   if (!event.target.matches(".dropbtn")) {
  //     var dropdowns = document.getElementsByClassName("dropdown-content");
  //     if (dropdowns) {
  //       var i;
  //       for (i = 0; i < dropdowns.length; i++) {
  //         var openDropdown = dropdowns[i];
  //         if (openDropdown.classList.contains("show")) {
  //           openDropdown.classList.remove("show");
  //         }
  //       }
  //     }
  //   }
  // });

  // const [dropActive, setDropdown] = useState("false");
  // const toggle = (previous) => !previous;

  // const showDrop = function () {

  //   console.log("check");

  //   setDropdown(toggle);

  //   console.log("check2");
  // };

  return (
    <div id="header">
      <div
        className="nav-above"
        onClick={() => {
          window.location.replace("https://r-e-a-l.it/capture");
        }}
      >
        <img
          src="https://r-e-a-l.it/capture/images/icons/product-cycle.gif"
          alt="Reality logo, cycling through products"
        />
      </div>
      <div className="nav-outer">
        <div className="nav-table">
          <ul className="nav-list">
            <li id="nav-equipment" className="dropdown">
              {/* https://r-e-a-l.it/solutions */}
              {/* onClick={showDrop} */}
              <a>equipment</a>
              {/* <a href="https://r-e-a-l.it/solutions">solutions</a> */}
              <div id="solutions-drop" className="dropdown-content">
                <li className="dropdown">
                  <a href="#">robotic</a>
                  <div id="solutions-drop" className="dropdown-content submenu">
                    <a href="https://r-e-a-l.it/equipment/robotic/blkarc/">
                      blkarc
                    </a>
                  </div>
                </li>
                <li className="dropdown">
                  <a href="#">efficient</a>
                  <div id="solutions-drop" className="dropdown-content submenu">
                    <a href="https://r-e-a-l.it/equipment/efficient/blk2go/">
                      blk2go
                    </a>
                    <a href="https://r-e-a-l.it/equipment/efficient/pegasus-two-ultimate/">
                      pegasus two:ultimate
                    </a>
                    <a href="https://r-e-a-l.it/equipment/efficient/pegasus-backpack/">
                      pegasus:backpack
                    </a>
                  </div>
                </li>
                <li className="dropdown">
                  <a href="#">aerial</a>
                  <div id="solutions-drop" className="dropdown-content submenu">
                    <a href="https://r-e-a-l.it/equipment/aerial/blk2fly/">
                      blk2fly
                    </a>
                  </div>
                </li>
                <a href="#">localized</a>
                <a href="#">imaging</a>
                <li className="dropdown">
                  <a href="#">terrestrial</a>
                  <div id="solutions-drop" className="dropdown-content submenu">
                    <a href="https://r-e-a-l.it/equipment/terrestrial/rtc360/">
                      rtc360
                    </a>
                    <a href="https://r-e-a-l.it/equipment/terrestrial/blk360/">
                      blk360
                    </a>
                    <a href="https://r-e-a-l.it/equipment/terrestrial/p-series/">
                      p-series
                    </a>
                  </div>
                </li>
              </div>
            </li>
            <li>
              <a>software</a>
            </li>
            <li>
              <a href="https://r-e-a-l.it/services">services</a>
            </li>
            <li className="dropdown">
              <a href="#">industries</a>
              <div id="industries-drop" className="dropdown-content">
                <a href="https://r-e-a-l.it/industries/surveying">surveying</a>
                <a href="https://r-e-a-l.it/industries/architecture">
                  architecture
                </a>
                <a href="https://r-e-a-l.it/industries/engineering">
                  engineering
                </a>
                <a href="https://r-e-a-l.it/industries/construction">
                  construction
                </a>
                <a href="https://r-e-a-l.it/industries/manufacturing">
                  manufacturing
                </a>
                <a href="https://r-e-a-l.it/industries/energy">energy</a>
              </div>
            </li>
            <li>
              <a target="_blank" href="http://r-e-a-l.news">
                news
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.youtube.com/channel/UCDH9jd3buX-cCzKfBoUlFiA"
              >
                r-e-a-l.it/tv
              </a>
            </li>
            <li>
              <a href="https://r-e-a-l.it/contact">contact</a>
            </li>
            <li>
              <a href="https://www.r-e-a-l.it/en_CA/my/home">
                my.r-e-a-l.<span className="i">i</span>t
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="nav-burger">
        <svg
          className="ham hamRotate ham8"
          viewBox="0 0 100 100"
          width="80"
          onClick={(event) => {
            event.target.classList.toggle("active");
            toggleMenu(document.querySelector(".nav-mobile"));
          }}
        >
          <path
            className="line top"
            d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"
          />
          <path className="line middle" d="m 30,50 h 40" />
          <path
            className="line bottom"
            d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"
          />
        </svg>
      </div>
      <div className="nav-mobile" style={{ display: "none" }}>
        <div className="nav-mobile-inner">
          <h1>
            R-E-A-L.iT | <b>RTC360</b>
          </h1>
          <ul>
            <li>
              <div style={{ marginTop: "50px" }}></div>
              <hr />
              <h1>solutions</h1>
              <ul>
                <li>
                  <a href="#">equipment</a>
                </li>
                <li>
                  <a href="#">software</a>
                </li>
              </ul>
            </li>
            <li>
              <hr />
              <h1>services</h1>
              <ul>
                <li>
                  <a href="#">education</a>
                </li>
                <li>
                  <a href="#">support</a>
                </li>
                <li>
                  <a href="#">coaching</a>
                </li>
                <li>
                  <a href="#">help</a>
                </li>
                <li>
                  <a href="#">marketing</a>
                </li>
                <li>
                  <a href="#">rentals</a>
                </li>
              </ul>
            </li>
            <li>
              <hr />
              <h1>industries</h1>
              <ul>
                <li>
                  <a href="#">survey &amp; mapping</a>
                </li>
                <li>
                  <a href="#">construction</a>
                </li>
                <li>
                  <a href="#">manufacturing</a>
                </li>
                <li>
                  <a href="#">public safety</a>
                </li>
                <li>
                  <a href="#">vfx</a>
                </li>
              </ul>
            </li>
            <li>
              <hr />
              <h1>
                r-e-a-l.<span className="i">i</span>t/tv
              </h1>
            </li>
            <li>
              <hr />
              <h1>news</h1>
            </li>
            <li>
              <hr />
              <h1>contact</h1>
            </li>
            <li>
              <hr />
              <h1>my.R-E-A-L.iT</h1>
            </li>
          </ul>
        </div>
      </div>
      {/* <NavLink to="/">Home</NavLink>
      <NavLink to="/about">About</NavLink>
      <NavLink to="/contact">Contact</NavLink> */}
    </div>
  );
};

export default Header;
