import React from "react";

class Industries extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const props = this.props.content;
    return (
      <div
        className="industry"
        style={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={props.image} style={{ width: "100vw" }}></img>
        <div style={{ width: "80vw" }}>
          <h1>{props.title}</h1>
          <p>{props.blurb}</p>
        </div>
      </div>
    );
  }
}

export default Industries;
