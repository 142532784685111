/* libraries */
import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";

/* components */
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";

/* specialties */
import Home from "./templates/home/Home";
import Coming from "./templates/coming/Coming";
import Support from "./templates/services/support/Support";
import Coaching from "./templates/services/coaching/Coaching";

/* templates */
import Error from "./templates/error/Error";
import Contact from "./templates/contact/Contact";
import Product from "./templates/product/Product";
import Product2 from "./templates/product2/Product";
import Software from "./templates/software/Software";
import Industries from "./templates/industries/Industries";

/* content */
import BLKARC from "./content/products/BLKARC";
import BLK2GO from "./content/products/BLK2GO";
import PegasusTwoUltimate from "./content/products/PegasusTwoUltimate";
import PegasusBackpack from "./content/products/PegasusBackpack";
import BLK2FLY from "./content/products/BLK2FLY";
import RTC360 from "./content/products/RTC360";
import PSeries from "./content/products/PSeries";
import BLK360 from "./content/products/BLK360";

import Cyclone_Register360 from "./content/software/Cyclone-Register360";
import Cyclone_REGISTER from "./content/software/Cyclone-Register";
import Cyclone_3DR from "./content/software/Cyclone-3DR";

import Surveying from "./content/industries/Surveying";
import Construction from "./content/industries/Construction";
import Energy from "./content/industries/Energy";
import Manufacturing from "./content/industries/Manufacturing";
import Engineering from "./content/industries/Engineering";
import Architecture from "./content/industries/Architecture";

import Blk2goGS from "./content/products/BLK2GO-GS";
import Template from "./content/products/Template";

// var sitemap = require("./sitemap.json");

const URL = window.location.href;
// const map = JSON.parse(JSON.stringify(sitemap));

function incl(str) {
  if (URL.toLowerCase().includes(str)) {
    return true;
  } else {
    return false;
  }
}

function isHomePage() {
  var rtrn = false;

  switch (URL) {
    case "http://localhost:3000/":
      rtrn = true;
      break;
    case "http://r-e-a-l.boats":
      rtrn = true;
      break;
    case "http://r-e-a-l.boats/":
      rtrn = true;
      break;
    case "https://r-e-a-l.boats":
      rtrn = true;
      break;
    case "https://r-e-a-l.boats/":
      rtrn = true;
      break;
    case "http://test.r-e-a-l.it":
      rtrn = true;
      break;
    case "http://test.r-e-a-l.it/":
      rtrn = true;
      break;
    case "https://test.r-e-a-l.it":
      rtrn = true;
      break;
    case "https://test.r-e-a-l.it/":
      rtrn = true;
      break;
    case "https://www.test.r-e-a-l.it":
      rtrn = true;
      break;
    case "https://www.test.r-e-a-l.it/":
      rtrn = true;
      break;
    case "http://www.test.r-e-a-l.it":
      rtrn = true;
      break;
    case "http://www.test.r-e-a-l.it/":
      rtrn = true;
      break;
    case "http://r-e-a-l.it/":
      rtrn = true;
      break;
    default:
      rtrn = false;
  }

  return rtrn;
}

function getPage() {
  if (isHomePage()) {
    return <Product content={BLK2GO} />;
  } else if (incl("equipment")) {
    //
    // robotic
    //
    if (incl("robotic")) {
      if (incl("blkarc")) {
        return <Product content={BLKARC} />;
      }
      //
      // efficient
      //
    } else if (incl("efficient")) {
      if (incl("blk2go")) {
        return <Product content={BLK2GO} />;
      } else if (incl("pegasus-two-ultimate")) {
        return <Product content={PegasusTwoUltimate} />;
      } else if (incl("pegasus-backpack")) {
        return <Product content={PegasusBackpack} />;
      } else if (incl("testing")) {
        return <Product content={Blk2goGS} />;
      }
      //
      // aerial
      //
    } else if (incl("aerial")) {
      if (incl("blk2fly")) {
        return <Product content={BLK2FLY} />;
      }
      //
      // localized
      //

      //
      // imaging
      //

      //
      // terrestrial
      //
    } else if (incl("terrestrial")) {
      if (incl("rtc360")) {
        return <Product content={RTC360} />;
      } else if (incl("p-series")) {
        return <Product content={PSeries} />;
      } else if (incl("blk360")) {
        return <Product content={BLK360} />;
      }
      //
      // template
      //
    } else if (incl("template")) {
      return <Product content={Template} />;
    } else {
      return <Home />;
    }
  } else if (incl("software")) {
    if (incl("visionlidar")) {
      return <Software content={BLK2GO} />;
    } else if (incl("pointfuse")) {
    } else if (incl("cloudworx")) {
    } else if (incl("verity")) {
    } else if (incl("rithm")) {
    } else if (incl("visionlidar-365")) {
    } else if (incl("truview")) {
    } else if (incl("cyclone")) {
      if (incl("register360")) {
        return <Software content={Cyclone_Register360} />;
      } else if (incl("register")) {
        return <Software content={Cyclone_REGISTER} />;
      } else if (incl("3dr")) {
        return <Software content={Cyclone_3DR} />;
      } else if (incl("survey")) {
      } else if (incl("model")) {
      } else if (incl("enterprise")) {
      } else if (incl("publisher")) {
      }
    }
  } else if (incl("services")) {
    if (incl("coach")) {
      // return <Coaching />;
      return <Coming />;
    } else if (incl("support")) {
      // return <Support />;
      return <Coming />;
    } else if (incl("rentals")) {
      return <Coming />;
    } else if (incl("marketing")) {
      return <Coming />;
    } else if (incl("education")) {
      return <Coming />;
    }
  } else if (incl("industries")) {
    if (incl("surveying")) {
      return <Industries content={Surveying} />;
    } else if (incl("construction")) {
      return <Industries content={Construction} />;
    } else if (incl("energy")) {
      return <Industries content={Energy} />;
    } else if (incl("manufacturing")) {
      return <Industries content={Manufacturing} />;
    } else if (incl("engineering")) {
      return <Industries content={Engineering} />;
    } else if (incl("architecture")) {
      return <Industries content={Architecture} />;
    }
  } else if (incl("contact")) {
    return <Contact />;
  } else if (incl("template")) {
    return <Product content={Template} />;
  } else {
    return <Error />;
  }
}
class App extends Component {
  constructor() {
    super();
    this.state = {
      route: "signin",
    };
  }

  render() {
    return (
      <BrowserRouter>
        <div id="spacer"></div>
        <Header />
        {/* <Content /> */}
        {getPage()}
        {/* <div className="test">
          <Routes>
            <Route path="/" component={Home} exact />
            <Route path="/about" component={About} />
            <Route path="/contact" component={Contact} />
            <Route component={Error} />
          </Routes>
        </div> */}
        <Footer />
      </BrowserRouter>
    );
  }
}

export default App;
