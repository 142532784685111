const BLK360 = {
  headerItems: [
    "overview",
    "technology",
    "use cases",
    "specifications",
    "request a demo",
  ],
  landing: {
    productName: "BLK360",
    videoLink: "https://player.vimeo.com/video/420719336?background=1",
    subHeader: "TERRESTRIAL REALITY CAPTURE",
  },
  advertise: {
    active: true,
    title: "The smallest and lightest imaging laser scanner available",
    videoLink: "https://www.youtube.com/embed/gtqyGfyTGmc",
    paragraph:
      "With one-button operation and a powerful set of internal components, the BLK360 is a fast, easy, and simple way to revolutionize your workflow. ",
  },
  sketchfab: {
    gradientColor: "#161616",
    modelLink:
      "https://sketchfab.com/models/33f5b1d5a274459683970b6b70dc86de/embed?camera=0&preload=1&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_watermark_link=0&ui_watermark=0&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&ui_color=00fb02&scrollwheel=0",
  },
  videosActive: true,
  videoSections: [
    {
      active: true,
      id: "PRESENTING",
      title: "Unleash your creativity",
      video: "https://www.youtube.com/embed/IkidUADvfc4",
      paragraph:
        "Scan wherever, whenever with the small, lightweight imaging laser scanner that fits into a messenger bag. The BLK360 bring hassle-free scanning to any site, and any skillset. ",
    },
    {
      active: true,
      id: "USING",
      title: "Powerful features, on site and in the office",
      video: "https://www.youtube.com/embed/1jTXOk8201o",
      paragraph:
        "the BLK360 automatically stitches and registers captured scan data for in-field visualisation in the mobile version of Cyclone FIELD 360, transferring seamlessly to Cyclone REGISTER 360 or Cyclone REGISTER 360 (BLK Edition) via wifi to get you processing your data and creating deliverables at lightning speed. ",
    },
    {
      active: true,
      id: "DEMONSTRATING",
      title: "Capture your surroundings",
      video: "https://www.youtube.com/embed/fItL_LlBCIs",
      paragraph:
        "The Leica BLK360 captures the world around you with full-colour panoramic images overlaid on a high-accuracy point cloud.",
    },
    {
      active: true,
      id: "UNBOXING",
      title: "Sleek, simple, and compact",
      video: "https://www.youtube.com/embed/K11nj0ApZTQ",
      paragraph:
        "Hit the ground running with everything you need to bring reality capture technology to your workflow. ",
    },
  ],
  features: [
    {
      title: "TECHNOLOGY",
      topVideo: true,
      mediaLink: "",
      sections: [
        {
          title: "Simple",
          image:
            "https://shop.leica-geosystems.com/sites/default/files/2020-05/Leica_BLK360_Front2.jpg",
          paragraph:
            "One-button automatic operation. Small enough to take anywhere. Rechargeable lithium ion battery with secure WiFi data transfer. Completes a scan within minutes.",
        },
        {
          title: "POWERFUL",
          image:
            "https://shop.leica-geosystems.com/sites/default/files/2020-05/Leica_4_0370.jpg",
          paragraph:
            "LiDAR technology that captures 360,000 points per second. Three spherical, panoramic HDR cameras with a thermal imaging camera. Delivers 3D point clouds with millimeter accuracy.",
        },
        {
          title: "RELIABLE",
          image:
            "https://shop.leica-geosystems.com/sites/default/files/2020-05/Leica_Tripod-front_2.jpg",
          paragraph:
            "Create an accurate digital twin of your site with laser-based measurements.",
        },
      ],
    },
    {
      title: "USE CASES",
      topVideo: true,
      mediaLink: "",
      sections: [
        {
          title: "3D Model",
          image:
            "https://shop.leica-geosystems.com/sites/default/files/styles/before_after_photo/public/2020-04/slider2-1_0.jpg?itok=pvj5Jp7F",
          paragraph:
            "A BLK360 point cloud is all you need to make an accurate 3D model.",
        },
        {
          title: "2D FLOORPLAN",
          image:
            "https://shop.leica-geosystems.com/sites/default/files/styles/before_after_photo/public/2020-04/slider1-2.png?itok=jnisT6yy",
          paragraph: "Slice the point cloud to create a precise 2D floorplan.",
        },

        {
          title: "3D MESH",
          image:
            "https://shop.leica-geosystems.com/sites/default/files/styles/before_after_photo/public/2020-05/wave-statue-mesh.jpg?itok=g0iTT7pY",
          paragraph:
            "Convert your points clouds into a mesh for photorealistic visualization.",
        },
        // {
        //   title: "VIRTUAL TOURS",
        //   image: "Video, make into animated GIF",
        //   paragraph:
        //     "Use BLK360 high-resolution photospheres to create immersive virtual tours. Learn more about remote collaboration with BLK360 and Leica TruView.",
        // },
        // {
        //   title: "",
        //   image: "",
        //   paragraph: "",
        // },
      ],
    },
  ],
  statistics: {
    specsTitle: "Specifications",
    specsVideo:
      "https://player.vimeo.com/video/500181508?background=1&autoplay=1",
    smallTitle: "Get the specs.",
    statsImage:
      "https://shop.leica-geosystems.com/sites/default/files/inline-images/BLK2GO-measurement-drawing_v2_0.png",
    imageRotation: "",
    statsItems: [
      {
        section: "DESIGN & PHYSICAL",
        stats: [
          {
            above: "HOUSING",
            under: "Black anodized aluminium",
          },
          {
            above: "",
            under: "Diameter: 100 (3.94 in.), Height: 165 mm (6.5 in.)",
          },
          {
            above: "WEIGHT",
            under: "1 kgg (g including battery)",
          },
          {
            above: "MOUNTING",
            under: "Button-press quick release",
          },
        ],
      },
      {
        section: "OPERATION",
        stats: [
          {
            above: "ON SCANNER",
            under: "One-button operation",
          },
          {
            above: "CONTROL",
            under:
              "Leica Cyclone FIELD 360 app for iPad or Android tablets including: Remote control of scan functions, 2D & 3D data viewing, Tagging, Automatic alignment of scans",
          },
          {
            above: "WIRELESS",
            under: "Intergrated wireless LAN (802.11 b/g/n)",
          },
          {
            above: "DATA STORAGE",
            under: "Storage for > 100 setups",
          },
        ],
      },
      {
        section: "POWER",
        stats: [
          {
            above: "INTERNAL",
            under: "Internal,rechargeable Li-Ion battery (Leica GEB121)",
          },
          {
            above: "EXTERNAL",
            under: "Typically >40 setups",
          },
        ],
      },
      {
        section: "SCANNING",
        stats: [
          {
            above: "DISTANCE MEASUREMENT",
            under:
              "High speed time fo flight enhanced by Waveform Digitizing (WFD) technology, 830 nm wavelength",
          },
          {
            above: "LASER CLASS",
            under: "1 (in accordance with IEC 60825-1:2014)",
          },
          {
            above: "FIELD OF VIEW",
            under: "360°C (HORIZONTAL), 300°C (VERTICAL)",
          },
          {
            above: "RANGE",
            under: "0.6m (MIN), 60m (MAX)",
          },
          {
            above: "SPEED",
            under: "up to 360,000 pts/sec",
          },
          {
            above: "DENSITY",
            under: "3 user selectable settings (3/6/12mm @ 10m)",
          },
        ],
      },
      {
        section: "IMAGING",
        stats: [
          {
            above: "CAMERA",
            under:
              "15 Mpixel 3-camera system, 150Mpx full dome capture, HDR, LED flash Calibrated spherical image, 360° x 300° ",
          },
          {
            above: "SPEED",
            under: "Min 4 minutes for full-dome",
          },

          {
            above: "HDR",
            under: "3 brackets",
          },
          {
            above: "THERMAL",
            under:
              "FLIR technology based longwave infrared camera Thermal panoramic image, 360° x 70° ",
          },
        ],
      },
      {
        section: "ENVIRONMENTAL",
        stats: [
          {
            above: "OPERATING TEMPERATURE",
            under: "5° (MIN), 40° (MAX)",
          },
          {
            above: "STORAGE TEMPERATURE",
            under: "° (MIN ), ° (MAX)",
          },

          {
            above: "IP RATING",
            under: "Solid particle/liquid ingress protection IP54 (IEC 60529)",
          },
        ],
      },
      {
        section: "NAVIGATION SENSORS",
        stats: [
          {
            above: "VISUAL INERTIAL SYSTEM",
            under: "",
          },
          {
            above: "TILT",
            under: "Automatic tilt measurements",
          },

          {
            above: "ALTIMETER",
            under: "",
          },
          {
            above: "COMPASS",
            under: "",
          },
          {
            above: "GNSS",
            under: "",
          },
        ],
      },
      {
        section: "ACCURACY",
        stats: [
          {
            above: "ANGULAR",
            under: "",
          },
          {
            above: "RANGE",
            under: "",
          },

          {
            above: "3D POINT ACCURACY",
            under: "6 mm@ 10m, 8 mm@ 20m, 14 mm@ 50m",
          },
        ],
      },
      {
        section: "DATA PROCESSING",
        stats: [
          {
            above: "DATA TRANSFER",
            under: "USB, Wireless",
          },
          {
            above: "DESKTOP SOFTWARE",
            under:
              "Cyclone REGISTER, Cyclone REGISTER 360, Cyclone REGISTER 360 BLK Edition",
          },
        ],
      },
    ],
  },
  datasets: [
    "https://blk2021.com/wp-content/uploads/2021/10/BLK2FLY_SpecSheet-1.pdf",
    "https://blk2021.com/wp-content/uploads/2021/10/BLK2FLY_SpecSheet-1.pdf",
  ],

  ordering: [
    {
      title: "Ready to learn more?",
      paragraph:
        "Our EXPERTS@R-E-A-L.iT are ready to help you with onboarding this new technology in your business. Whether you need to train your employees with this new hardware, or have questions about what software solutions to use in the office to get the most out of your data, we are ready to assist you at every step of the process. Check out our coaching plans at https://r-e-a-l.it/services/coach. Still have questions? Click the button below to start a conversation with one of our experts.",
      buttonText: "TALK TO AN EXPERT@R-E-A-L.iT",
    },
    {
      title: "Ready to order?",
      paragraph:
        "Laser scanning is a revolutionary way to supercharge your workflow. At R-E-A-L.iT, we can help you increase the quality of your deliverables while also helping you work faster and smarter. Our hardware provides you with a lightning fast way to gather measurements, helping your teams get in and out of sites in record time, with more data than they could ever capture using traditional measurement techniques. Ready to buy? Click the button below to place an order.",
      buttonText: "ORDER NOW",
    },
  ],
};
export default BLK360;
